import React from 'react';
import Draggable from 'react-draggable';
import {listChildrenofNodeID, updateNodeXY, getUltimateParentofNodeID, isNodeTopLevel} from '../../utils';
import {isTargetingNode, isTargetingEdgeOfNode, isTargetingSelf} from '../../targeting_utils';
import { NodeContainerCanNestInto, NodeNestable, NodeOnlyNestsTopLevel } from './NodeConsts';

class DraggableWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.onMouseDown=this.onMouseDown.bind(this);
        this.onDragStart=this.onDragStart.bind(this);
        this.onDrag=this.onDrag.bind(this);
        this.onDragStop=this.onDragStop.bind(this);
        this.state = {
            target:{target_node_id:0, target_node_topbottom:false},
            target_style:{target_node_save_zIndex:"", target_node_save_margin:"", target_children_save_padding:""}
        };
        this.saveTargetStyle=this.saveTargetStyle.bind(this);
        this.highlightTarget=this.highlightTarget.bind(this);
        this.restoreTargetStyle=this.restoreTargetStyle.bind(this);
    }

    onMouseDown(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        this.props.promoteNodeZIndex(getUltimateParentofNodeID(node_id, node_map).node_id);
        this.props.updateEdge(getUltimateParentofNodeID(node_id, node_map).node_id);
    }

    onDragStart(e, data){
        this.props.setDragging(true, this.props.node_id);
        e.stopPropagation();
        e.preventDefault(); 
    }

    saveTargetStyle(targetedNodeId){
        let node_map = this.props.getNodeMap();
        let dom_node = node_map.get(targetedNodeId).myRef.current;
        let children_node = null;
        if(node_map.get(targetedNodeId).childrenRef)
            children_node = node_map.get(targetedNodeId).childrenRef.current;

        this.setState({target_style:{
            target_node_save_zIndex:dom_node.style.zIndex,
            target_node_save_margin: dom_node.style.padding,
            target_children_save_padding: children_node?children_node.style.padding:null
        }});
    }

    highlightTarget(node, targetedNodeId, targetNodeIdTopBottom){

        if(targetedNodeId){
            let node_map = this.props.getNodeMap();
            let target_node = node_map.get(targetedNodeId);
            let dom_node = target_node.myRef.current;
            let children_node = target_node.childrenRef.current;

            if(isTargetingSelf(targetedNodeId, node.node_id, node_map))
                return;

            this.saveTargetStyle(targetedNodeId);

            //dom_node.style.zIndex = node.z_index-1; //there was an issue with this causing the target to hop in front of the node being dragged in some cases

            if(!isNodeTopLevel(target_node))
                switch(targetNodeIdTopBottom){
                    case "top_fifth":
                    dom_node.style.paddingTop = node.height+"px"; //top right bottom left
                    break;

                    case "bottom_fifth":
                    dom_node.style.paddingBottom = node.height+"px";
                    break;

                    default:
                    if(!(node.parent_id === target_node.node_id))
                        children_node.style.paddingBottom = node.height+"px";
                    break;
                }
            else
                if(!(node.parent_id === target_node.node_id))
                    children_node.style.paddingBottom = node.height+"px";
        }
        this.setState({target:{target_node_id:targetedNodeId, target_node_topbottom:targetNodeIdTopBottom}});
    }

    restoreTargetStyle(){
        let dom_node;
        let children_node = null;
        let node_map = this.props.getNodeMap();
        let target_node_id = this.state.target.target_node_id;

        if(target_node_id) {
            let target_node = node_map.get(target_node_id);

            if(target_node.childrenRef)
                children_node = target_node.childrenRef.current;

            dom_node= target_node.myRef.current;

            dom_node.style.zIndex = this.state.target_style.target_node_save_zIndex;
            dom_node.style.padding = this.state.target_style.target_node_save_margin;
            
            children_node.style.padding = this.state.target_style.target_children_save_padding;

            this.setState(
                {target:{target_node_id:0},
                
                target_style:{target_node_save_zIndex:"", target_node_save_margin:"", target_children_save_padding:""}});
        }
    }

    onDrag(e, data){
        /*
            this.props.enable_nesting: disallow dragging into other nodes altogether
            isTargetingNode: find whether there is a target (excludes collapsed children, text&citations, the node being dragged)
        */
        let node_map = this.props.getNodeMap();
        let node_id = parseInt(data.node.id);
        let node = node_map.get(node_id);

        this.props.handleMousemove(e); // handle screen scrolling


        if(NodeNestable(node.node_type)){  //highlighting 
            let targetedNodeId = isTargetingNode(e.pageX, e.pageY, node_map, node_id, false);
            let targetNodeIdTopBottom = false;

            if(targetedNodeId){
                if(NodeOnlyNestsTopLevel(node.node_type)){ //container getting dragged into another container -> it will transform into a header
                    targetedNodeId=getUltimateParentofNodeID(targetedNodeId,node_map).node_id;
                    if(!NodeContainerCanNestInto(node_map.get(targetedNodeId).node_type))
                        targetedNodeId=null;
                }
                else
                    targetNodeIdTopBottom = isTargetingEdgeOfNode(e.pageX, e.pageY, node_map, targetedNodeId);
            }
            
            if(targetedNodeId!== this.state.target.target_node_id || targetNodeIdTopBottom!== this.state.target.target_node_topbottom){              
                this.restoreTargetStyle();//dehighlight old node
                this.highlightTarget(node, targetedNodeId, targetNodeIdTopBottom); //highlight new node or clear node target
            }
        }

        updateNodeXY(node_id, node_map, data.x, data.y, this.props.getDragging().dragging_node_id);

        if(!node.collapsed){
            let node_children=listChildrenofNodeID(node_id, node_map);

            for(let child_node_id of node_children)
                updateNodeXY(child_node_id, node_map, data.x, data.y, this.props.getDragging().dragging_node_id);
        }

        if(this.props.getDragging().is_dragging)
            this.props.updateNodeMap(node_map, () => {this.props.updateEdge(node_id);});
    }

    onDragStop(e, data){
        /*Scenarios:
            #1: Dragged into another node (including existing parent)
            #2: Dragged out of a node onto the canvas
            #3: Dragged from one top-level position to another top-level position*/


        
        let node_map = this.props.getNodeMap();
        let node_id = parseInt(data.node.id);
        let node = node_map.get(node_id);

        let targetedNodeId = isTargetingNode(e.pageX, e.pageY, node_map, node_id, false);

        if(NodeNestable(node.node_type)){//highlighting end
            this.restoreTargetStyle();
        }
        console.log("onDragStop");
        e.preventDefault(); 
        e.stopPropagation();

        this.props.setDragging(false);
        if(targetedNodeId && NodeNestable(node.node_type)){ //Scenario #1: Dragged into another node (including existing parent)
            this.props.savetoUndo("Drag and Nest");
            this.props.nestNode(targetedNodeId, node_id, isTargetingEdgeOfNode(e.pageX, e.pageY, node_map, targetedNodeId));
            return false;
        }
        
        if(node.parent_id){ //Scenario #2: Dragged out of a node onto the canvas
            this.props.savetoUndo("Drag and Unnest");
            this.props.unnestNode(node_id);
            return false;
        }

        //Scenario #3: Dragged from one top-level position to another top-level position
        this.props.updateEdge(node_id);
        return false;
    }

    render() {
        let draggable_key="";
        let defaultPosition = {x: 0, y: 0};

        let node_map = this.props.getNodeMap();
        let node = node_map.get(this.props.node_id);

        if(!node.parent_id) 
            defaultPosition = {x: node.x, y: node.y};
        else 
            draggable_key=node.draggable_key? node.draggable_key: ""+Math.random();

        if(this.props.enable_dragging)
            return (
                <Draggable
                    cancel={"textarea, label, button"}
                    onStart={this.onDragStart}
                    onDrag={this.onDrag}
                    onStop={this.onDragStop}
                    handle={this.props.handle}
                    key={node.node_id+draggable_key}
                    enableUserSelectHack={false}
                    defaultPosition={defaultPosition}
                    onMouseDown={this.onMouseDown} //cannot simply put this into the onStart handler, because then clicking on textareas won't trigger the zIndex adjustment
                    nodeRef={node.myRef}
                    grid={[2,2]}
                >
                    {this.props.children}
                </Draggable>
            );

        else
            return this.props.children;
    }
}

export default DraggableWrapper;