import React from 'react';
import {
    Menu, 
    FileInput,
    Classes,
    MenuItem, 
} from "@blueprintjs/core";

import NodeStruct from '../ScreenFrame'

import {stringToClassNodeType} from '../nodes/shared/NodeConsts';
import { Auth, Storage } from 'aws-amplify';

class LoadMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cloud_files:[],
            shared_cloud_files:[]
        }
        this.deserializeNode=this.deserializeNode.bind(this);
        this.deserializeNodeMap=this.deserializeNodeMap.bind(this);
        this.loadPlat=this.loadPlat.bind(this);
        this.loadCloudPlat=this.loadCloudPlat.bind(this);
        this.listCloudPlats=this.listCloudPlats.bind(this);
        this.listSharedCloudPlats=this.listSharedCloudPlats.bind(this);
    }
    
    deserializeNode(node_json){
        let nns = new NodeStruct();
        nns.x = node_json.x;
        nns.y = node_json.y;
        nns.width = node_json.width;
        nns.height = node_json.height;
        nns.node_id = parseInt(node_json.node_id);
        nns.node_type = stringToClassNodeType(node_json.node_type);
        nns.edges_from_nodes = node_json.edges_from_nodes;
        nns.edges_to_nodes = node_json.edges_to_nodes;
        if(node_json.node_state){
            if(node_json.node_state.node_text) nns.text = node_json.node_state.node_text;
            if(node_json.node_state.node_title) nns.text = node_json.node_state.node_title;
            if(node_json.node_state.element_text) nns.text = node_json.node_state.element_text;
            
            if(node_json.node_state.node_citation) nns.citation = node_json.node_state.node_citation;
            if(node_json.node_state.element_citation) nns.citation = node_json.node_state.element_citation;
        }
        else{
            if(node_json.text) nns.text = node_json.text;
            if(node_json.citation) nns.citation = node_json.citation;
        }
        
        if(node_json.nested_parent_id)
            nns.parent_id = node_json.nested_parent_id;
        else
            nns.parent_id = node_json.parent_id;

        if(typeof node_json.citation_enabled == "boolean")
            nns.citation_enabled = node_json.citation_enabled;
        else
            nns.citation_enabled = true;


        if(node_json.nested_child_nodes)
            nns.child_ids = node_json.nested_child_nodes;
        else
            nns.child_ids = node_json.child_ids;
        nns.z_index = node_json.z_index;
        nns.myRef=React.createRef();
        nns.childrenRef =  React.createRef();

        nns.checked = node_json.checked?node_json.checked:"false";

        nns.highlight = node_json.highlight?node_json.highlight:false;

        return nns;
    }


    deserializeNodeMap(a_str_node_map){
        let delete_nodes = [];
        let jsonNodeMap = JSON.parse(a_str_node_map);
        let node_map = new Map();
        let existing_node_map = this.props.getNodeMap();
        let update_nodes = [];

        let max_z_index = 0;

        for(let node of existing_node_map.values()) delete_nodes.push(node.node_id);
        
        for(let kv_pair of jsonNodeMap){
            node_map.set(kv_pair[0], this.deserializeNode(kv_pair[1]));
            if(node_map.get(kv_pair[0]).z_index>max_z_index) max_z_index=node_map.get(kv_pair[0]).z_index;
            update_nodes.push(kv_pair[0]);
        }

        this.props.loadNodeMap(node_map, max_z_index, delete_nodes, update_nodes);
    }

    loadPlat(e){
        let files = e.target.files;
        if (files.length <= 0) {
            return false;
        }
        
        let fr = new FileReader();
        
        fr.onload = (e)=>{
            this.deserializeNodeMap(e.target.result);
        }
        
        fr.readAsText(files.item(0));

        const { parentElement } = e.target; //to close the popover when a file is loaded
        parentElement.classList.add(Classes.POPOVER_DISMISS);
        parentElement.click();
    }

    async loadCloudPlat(name){
        let data = await Storage.get(name, {download:true, cacheControl: 'no-cache'});
        
        let fr = new FileReader();
        
        fr.onload = (e)=>{
            this.deserializeNodeMap(e.target.result);
        }
        
        fr.readAsText(data.Body);
    }

    async listCloudPlats(){
        let info = await Auth.currentUserInfo();
        if(!info)
            return;
        if(!info.attributes)
            return;
        const data = await Storage.list(info.attributes.email+"/");
        this.setState({cloud_files:data.map((obj) => <MenuItem
                                            icon="code-block"
                                            text={obj.key}
                                            key={obj.key}
                                            onClick={()=>this.loadCloudPlat(obj.key)}
                                        />
        )});
    }

    async listSharedCloudPlats(){
        let info = await Auth.currentUserInfo();
        if(!info)
            return;
        if(!info.attributes)
            return;
        const data = await Storage.list("shared/");
        this.setState({shared_cloud_files:data.map((obj) => <MenuItem
                                            icon="code-block"
                                            text={obj.key}
                                            key={obj.key}
                                            onClick={()=>this.loadCloudPlat(obj.key)}
                                        />
        )});
    }

    componentDidMount(){
        this.listCloudPlats();
        this.listSharedCloudPlats();
    }

    render() { 
        return    (
            <Menu>
                <FileInput
                    text="Load Local Plat . . ."
                    inputProps={
                        {
                            accept: ".plat,.json1,.json", id: 
                            'selectFiles'
                        }
                    }
                    onInputChange={this.loadPlat}
                />
                <MenuItem
                    icon="cloud-download"
                    text="Load from Private Cloud"
                    onClick={this.listCloudPlats}
                >
                    {this.state.cloud_files}
                </MenuItem>
                <MenuItem
                    icon="cloud-download"
                    text="Load from Shared Cloud"
                    onClick={this.listSharedCloudPlats}
                >
                    {this.state.shared_cloud_files}
                </MenuItem>
            </Menu>
        );
    }
}
 
export default LoadMenu;




