import React, { Component } from 'react';

import _ from 'lodash';
import Edge from '../edges/Edge'
import {listChildrenofNodeID, getUltimateParentofNodeID} from '../utils'



class NodeRenderer extends Component {

    state = {
        rendered_nodes: [],
        rendered_edges: [],
    };

    static getDerivedStateFromProps(props, state){
        
        let rendered_nodes = state.rendered_nodes;
        let rendered_edges = state.rendered_edges;
        let node_map = props.getNodeMap();

//NODES RENDERING
        function handleUpdatedNodesMap(update_all){
            let addressed_node_ids = [];

            function createRenderedNode(node){
                addressed_node_ids.push(node.node_id);
    
                if(node.node_id) {
                    
                    let childNodes = [];
    
                        for(let child_id of node.child_ids){
                            let more = createRenderedNode(node_map.get(child_id));
                            if(more){
                                childNodes.push(more);
                            }
                        }
                    if(!node.draggable_key) node.draggable_key = ""+Math.random();

                    return <node.node_type
                                    setDragging={props.setDragging}
                                    getDragging={props.getDragging}
                                    nestNode={props.nestNode}
                                    unnestNode={props.unnestNode}
                                    handleMousemove={props.handleMousemove}
    
                                    globalState={props.globalState}
                                    handleEdgeDrag={props.handleEdgeDrag}
                                    getNodeMap={props.getNodeMap}
                                    updateNodeMap={props.updateNodeMap}

                                    addNode={props.addNode}
                                    removeNode={props.removeNode}

                                    promoteNodeZIndex={props.promoteNodeZIndex}
                                    node_id={node.node_id}
                                    key={node.node_id}
    
                                    children={childNodes}
    
                                    updateNode={props.updateNode}
                                    updateEdge={props.updateEdge}

                                    
                                    savetoUndo={props.savetoUndo}
                                    
                                    />
                }
                else
                    return null;
            }

            function renderID(node,check_for_old){
                let newly_rendered_node = createRenderedNode(node);
                if(newly_rendered_node) {
                    if(check_for_old)
                        rendered_nodes = _.filter(rendered_nodes, (obj) => !(parseInt(obj.key) === node.node_id));
                    rendered_nodes.push(newly_rendered_node);
                }
            }

            let parent_ids = [];

            if(update_all) {
                rendered_nodes = [];
                for(let node of node_map.values())
                    if(!node.parent_id)
                        renderID(node,false);
                props.resetUpdateAllNodes();
            }

            else {
                //first, iterate through updated_nodes_map to get all the top-level parents
                for(let node_id of props.updated_nodes_map.values()) 
                    if(node_map.get(node_id))
                        parent_ids.push(getUltimateParentofNodeID(node_id, node_map).node_id);
                    
                //second, uniq. the top-level parents list so we don't run any twice
                parent_ids = _.uniq(parent_ids);

                //third, render all the top-level parents
                for(let node_id of parent_ids.values())
                    renderID(node_map.get(node_id),true);
    
                props.resetUpdatedNodesMap(addressed_node_ids);
            }
        }

        function handleDeletedNodesMap(){
            let deleted_node_ids = [];
            let unique_deleted_nodes_map = _.uniq(props.deleted_nodes_map);
            for(let node_id of unique_deleted_nodes_map.values()){
                rendered_nodes = _.filter(rendered_nodes, (obj) => !(parseInt(obj.key) === node_id));
                deleted_node_ids.push(node_id);
            }
            props.resetDeletedNodesMap(deleted_node_ids);
        }

//EDGES RENDERING

        const delete_edge = (key) => {
            console.log("delete_edge "+key);
            rendered_edges = _.filter(rendered_edges, (obj) => !(obj.key === key));
        }
        
        const handleDeletedEdgesMap = () => {

            let deleted_edge_ids = [];
            console.log("handleDeletedEdgesMap "+deleted_edge_ids);
            for(let key of props.deleted_edges_map)
            {
                delete_edge(key);
                deleted_edge_ids.push(key);
            }
            props.resetDeletedEdgesMap(deleted_edge_ids);
        }

        const handleUpdatedEdgesMap = () => {

            const package_edge = (node_1, node_2) => {
                return <Edge key={node_2.node_id + " " + node_1.node_id} start_node={node_1} end_node={node_2} getNodeMap={props.getNodeMap} getDragging={props.getDragging}/>;
            }

            let addressed_edge_ids = [];
            let unique_updated_edges_map = _.uniq(props.updated_edges_map);
            let children_node_id_list = [];

            for(let possible_parent_node of unique_updated_edges_map)
                children_node_id_list=children_node_id_list.concat(listChildrenofNodeID(possible_parent_node, node_map));

            unique_updated_edges_map=_.uniq(unique_updated_edges_map.concat(children_node_id_list));

            for(let start_node_id of unique_updated_edges_map) {
                let start_node=node_map.get(start_node_id);

                for(let end_node_id of start_node.edges_to_nodes){
                    let end_node = node_map.get(end_node_id);
                    let new_edge = package_edge(end_node, start_node);
                    delete_edge(start_node_id+" "+end_node_id);
                    rendered_edges.push(new_edge);
                }

                for(let end_node_id of start_node.edges_from_nodes){
                    let end_node = node_map.get(end_node_id);
                    let new_edge = package_edge(start_node, end_node);
                    delete_edge(end_node_id+" "+start_node_id);
                    rendered_edges.push(new_edge);
                }
                addressed_edge_ids.push(start_node_id);
            }
            props.resetUpdatedEdgesMap(addressed_edge_ids);
        }

        const handleDeleteAllEdges=() => {
            rendered_edges=[];
            props.resetDeleteAllEdges();
        }

        //CORE LOGIC

        let new_state = state;
        let new_state_changed = false;

        if(props.deleted_nodes_map.length || props.updated_nodes_map.length || props.update_all_nodes){            
            if(props.update_all_nodes)
                handleUpdatedNodesMap(true);
            if(props.deleted_nodes_map.length)
                handleDeletedNodesMap();
            if(props.updated_nodes_map.length)
                handleUpdatedNodesMap();
            new_state.rendered_nodes=rendered_nodes;
            new_state_changed=true;
        }


        if(!new_state_changed && (props.deleted_edges_map.length || props.updated_edges_map.length || props.delete_all_edges)){
            if(props.delete_all_edges)
                handleDeleteAllEdges();
            if(props.deleted_edges_map.length)
                handleDeletedEdgesMap();
            if(props.updated_edges_map.length)
                handleUpdatedEdgesMap();
            new_state.rendered_edges=rendered_edges;
            new_state_changed=true;
        }

        if(new_state_changed)
            return new_state;
        else 
            return null;
    }


    render() {
        return <>
                    {this.state.rendered_nodes}
                    {this.state.rendered_edges}
                </>;
    }
}

export default NodeRenderer;
