import React, { Component } from 'react';
import SideBar from './side_bar/SideBar'
import TopBar from './top_bar/TopBar'
import NodeRenderer from './nodes/NodeRenderer'

import { getUltimateParentofNodeID} from './utils';
import {isTargetingNode} from './targeting_utils';
import { getColorFromType } from './nodes/shared/NodeConsts';

class ScreenFrame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_mode: true,
            checkbox_mode: false,
            citations_mode: true,

            drawer_open: false,
            
            is_dragging: false, //dragging nodes
            dragging_node_id: 0,
            
            is_resizing: false, //resizing nodes
            resizing_node_id: 0,
            
            mouse_is_down: false, //for dragging buttons from top bar
            dragged_top_button: undefined, //DnD button info

            running_line: undefined, //line drawing
            running_line_coords: this.initRunningLineCoords(),
            running_line_mouse_down: false,
            running_line_source_node: undefined,

            target_node_id:0, //target tracking for DnD or running line
            target_node_save_style:"",

            text_content: "", //sidebar law import content
            citation_content: "", //sidebar citation content
            notes_content: "", //sidebar notes content
        };

        this.old_document_onmousemove_handler = document.onmousemove;
        this.timer = undefined;
        this.event = undefined;
        this.top_bar_height = 0;

        this.edge_update_timer = undefined;

        this.draggedButton=this.draggedButton.bind(this);
        this.draggedButtonHighlighting=this.draggedButtonHighlighting.bind(this);

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.toggleCheckboxMode = this.toggleCheckboxMode.bind(this);

        this.toggleDrawer = this.toggleDrawer.bind(this);

        this.setDragging = this.setDragging.bind(this);
        this.getDragging = this.getDragging.bind(this);
        this.toggleCitationsMode = this.toggleCitationsMode.bind(this);

        this.handleMouseUp = this.handleMouseUp.bind(this);

        this.draggedLineHighlighting=this.draggedLineHighlighting.bind(this);
        this.runningLineMouseMove = this.runningLineMouseMove.bind(this);
        this.runningLineMouseDown = this.runningLineMouseDown.bind(this);

        this.handleMousemove = this.handleMousemove.bind(this);
        this.checkForWindowScroll = this.checkForWindowScroll.bind(this);
        this.adjustWindowScroll = this.adjustWindowScroll.bind(this);

        this.dragBackgroundMouseDown = this.dragBackgroundMouseDown.bind(this);
        this.dragBackgroundMouseMove = this.dragBackgroundMouseMove.bind(this);
        this.dragBackgroundMouseUp = this.dragBackgroundMouseUp.bind(this);

        this.DnDunhighlight = this.DnDunhighlight.bind(this);

        
        this.onChangeLaw=this.onChangeLaw.bind(this);
        this.onChangeCitation=this.onChangeCitation.bind(this);
        this.onChangeNotes=this.onChangeNotes.bind(this);
    }

    componentDidMount(){
        document.addEventListener( "mousemove", this.handleMousemove, false );
        this.top_bar_height = document.getElementById("top_bar").getBoundingClientRect().height;

        window.onbeforeunload = function(e) {
            var dialogText = 'Confirm';
            e.returnValue = dialogText;
            return dialogText;
          };
        
    }

    initRunningLineCoords(){
        return {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
        }
    }

    runningLineMouseDown(e, from_node_id){
        let node_map = this.props.getNodeMap();
        let node = node_map.get(from_node_id);

        let running_line_coords = this.initRunningLineCoords()

        if(node){
            running_line_coords.x1 = node.x+(node.width/2);
            running_line_coords.y1 = node.y;
        }
        else{
            running_line_coords.x1 = e.pageX;
            running_line_coords.y1 = e.pageY;
        }

        this.setState({
            running_line_mouse_down: true,
            running_line_coords,
            running_line_source_node: from_node_id,
        })
    }

    dragBackgroundMouseDown(e){
        console.log("dragBackgroundMouseDown");
        if(e.target!==e.currentTarget)
            return;
        document.getElementById("workspace").style.cursor = 'grabbing';
        this.setState({
            drag_background_mouse_down: true,
            drag_background_coords: {
                x:e.clientX,
                y:e.clientY,
                left: window.scrollX,
                top: window.scrollY
            }
        },()=>{
            document.addEventListener('mousemove', this.dragBackgroundMouseMove);
            document.addEventListener('mouseup', this.dragBackgroundMouseUp);
        });
    }

    dragBackgroundMouseMove(e){
        console.log("dragBackgroundMouseMove");

        if(this.state.drag_background_mouse_down){
            const dx = e.clientX - this.state.drag_background_coords.x;
            const dy = e.clientY - this.state.drag_background_coords.y;
        
            // Scroll the element
            window.scrollTo(this.state.drag_background_coords.left - dx, this.state.drag_background_coords.top - dy);
        };
    }

    dragBackgroundMouseUp(e){
        console.log("dragBackgroundMouseUp");
        
        document.getElementById("workspace").style.cursor = 'auto';
        this.setState({
            drag_background_mouse_down: false
        },()=>{
            document.removeEventListener('mousemove', this.dragBackgroundMouseMove);
            document.removeEventListener('mouseup', this.dragBackgroundMouseUp);
        })
    }


    draggedLineHighlighting(flag, targetedNodeId,save_style){
        if(flag)
            this.setState({target_node_id:targetedNodeId, target_node_save_style:save_style});
        else
            return {target_node_id:this.state.target_node_id, target_node_save_style: this.state.target_node_save_style};
    }

    runningLineMouseMove(e){

        if(this.state.running_line_mouse_down){
            let node_map = this.props.getNodeMap();
            let source_node = node_map.get(this.state.running_line_source_node);
            let color = getColorFromType(source_node.node_type, source_node.node_id, node_map);
          

            let running_line = <div style={{zIndex:1000000000}}><svg className="edges"><line 
                x1={"" + this.state.running_line_coords.x1} 
                y1={"" + this.state.running_line_coords.y1} 
                x2={"" + e.pageX} 
                y2={"" + e.pageY} 
                style={{stroke: color, strokeWidth: "1.5px"}} 
            /></svg></div>
            let running_line_coords = this.state.running_line_coords;
            running_line_coords.x2 = e.pageX;
            running_line_coords.y2 = e.pageY;


            //HIGHLIGHTING
            let targeted_node_id = isTargetingNode(running_line_coords.x2, running_line_coords.y2, node_map);
            let save_style = "";
            if(targeted_node_id!== this.draggedLineHighlighting(false).target_node_id){
                //dehighlight old node
                let dom_node;
                if(this.draggedLineHighlighting(false).target_node_id) {
                    dom_node= document.getElementById(this.draggedLineHighlighting(false).target_node_id);
                    dom_node.style.boxShadow = this.draggedLineHighlighting(false).target_node_save_style;
                }

                //highlight new node
                if(targeted_node_id && getUltimateParentofNodeID(targeted_node_id, node_map)!== getUltimateParentofNodeID(this.state.running_line_source_node, node_map)){
                    let target_node = node_map.get(targeted_node_id);
                    dom_node = target_node.myRef.current;
                    save_style = dom_node.style.boxShadow;
                    dom_node.style.boxShadow = "0px 0px 5px 5px "+getColorFromType(target_node.node_type, targeted_node_id, node_map);
                }
                this.draggedLineHighlighting(true, targeted_node_id,save_style);
            } //end highlighting


            //END HIGHLIGHTING
            this.setState({
                running_line_coords,
                running_line,
            })
        }
      }


    DnDunhighlight(){
        if(this.state.target_node_id){
            //dehighlight old node
            let node_map = this.props.getNodeMap();
            let node = node_map.get(this.state.target_node_id);
            let dom_node = node.myRef.current;
            let children_node = null;
            if(node.childrenRef)
                children_node = node.childrenRef.current;
            dom_node.style.zIndex = this.state.target_node_save_zIndex;
            dom_node.style.padding = this.state.target_node_save_margin;
            if(children_node)
                children_node.style.padding = this.state.target_children_save_padding;
            this.setState({
                target_node_id:0,
                target_node_save_zIndex:"",
                target_node_save_margin:"",
                target_children_save_padding:""
            });
        }
    }

    handleMouseUp(e){
        console.log("handleMouseUp");
        document.onmousemove = this.old_document_onmousemove_handler;

        let node_map = this.props.getNodeMap();
        let top = document.getElementById("top_bar");
        let top_height = top.getBoundingClientRect().height;

        if(this.state.mouse_is_down && e.pageY > top_height){ //drag-n-drop buttons from top bar
            let target_node_id = this.state.target_node_id;
            let topbottom = this.state.targetNodeIdTopBottom;
            let sibling_node_id = null;

            let target_node = node_map.get(target_node_id);
            if(target_node)
                if(target_node.parent_id) //if 'highlighting' moved the node out of the way, target its parent instead
                    if(topbottom){
                        sibling_node_id = target_node_id;
                        target_node_id=node_map.get(target_node.parent_id).node_id;
                    }

            this.props.addNodeOfType(this.state.dragged_top_button, e.pageX, e.pageY, target_node_id, sibling_node_id, topbottom);
            this.DnDunhighlight();
        }

        if(this.state.running_line_mouse_down){ //adding a new line
            let targeted_node_id = isTargetingNode(this.state.running_line_coords.x2, this.state.running_line_coords.y2, node_map);
            if(targeted_node_id && getUltimateParentofNodeID(targeted_node_id, node_map)!== getUltimateParentofNodeID(this.state.running_line_source_node, node_map))
               {
                let dom_node = document.getElementById(targeted_node_id);
                dom_node.style.boxShadow = this.state.target_node_save_style;
                this.setState({target_node_id:0, target_node_save_style:""});
                this.props.addEdge(targeted_node_id, this.state.running_line_source_node);
            }

            this.setState({
                running_line:undefined,
                running_line_mouse_down:false,
                running_line_coords: this.initRunningLineCoords(),
                running_line_source_node: undefined
            })
        }

        this.setState({
            dragged_top_button: undefined,
            mouse_is_down : false,
        })
    }

    draggedButton(dragged_top_button){
        this.setState({
            dragged_top_button,
            mouse_is_down : true
        });
    }

    draggedButtonHighlighting(flag, targetedNodeId,targetNodeIdTopBottom,save_zIndex,save_margin,save_padding){
        if(flag)
            this.setState({
                target_node_id:targetedNodeId,
                targetNodeIdTopBottom:targetNodeIdTopBottom,
                target_node_save_zIndex:save_zIndex,
                target_node_save_margin:save_margin,
                target_children_save_padding:save_padding
            });
        else
            return {
                target_node_id:                 this.state.target_node_id,
                targetNodeIdTopBottom:          this.state.targetNodeIdTopBottom,
                target_node_save_zIndex:        this.state.target_node_save_zIndex,
                target_node_save_margin:        this.state.target_node_save_margin,
                target_children_save_padding:   this.state.target_children_save_padding
            };
    }

    setDragging(bool, node_id = 0){
        this.setState({is_dragging: bool, dragging_node_id: node_id});
    }
    
    getDragging(){
        return {is_dragging:this.state.is_dragging, dragging_node_id:this.state.dragging_node_id};
    }
    
    toggleEditMode(){
        this.setState({
            edit_mode: ! this.state.edit_mode
        },  () => {this.props.updateAllNodes();});
    }
    toggleCheckboxMode(){
        this.setState({
            checkbox_mode: ! this.state.checkbox_mode
        },  () => {this.props.updateAllNodes();});
    }

    toggleCitationsMode(){
        this.setState({
            citations_mode: ! this.state.citations_mode
        },  () => {this.props.updateAllNodes();});
    }

    toggleDrawer(){
        this.setState({
            drawer_open: !this.state.drawer_open
        });
    }


    // credit to https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
    //
    handleMousemove( event ) {
        this.event = event;
        var edgeSize = 50

        var viewportX = event.clientX;
        var viewportY = event.clientY;

        var viewportWidth = document.documentElement.clientWidth;
        var viewportHeight = document.documentElement.clientHeight;

        var edgeTop = edgeSize + this.top_bar_height;
        var edgeLeft = edgeSize;
        var edgeBottom = ( viewportHeight - edgeSize );
        var edgeRight = ( viewportWidth - edgeSize );

        let should_auto_scroll = this.state.is_dragging || this.state.running_line_mouse_down || this.state.mouse_is_down
        var isInLeftEdge = ( viewportX < edgeLeft) && should_auto_scroll ;
        var isInRightEdge = ( viewportX > edgeRight ) && should_auto_scroll; 
        var isInTopEdge = ( viewportY < edgeTop) && should_auto_scroll && !this.state.dragged_top_button;
        var isInBottomEdge = ( viewportY > edgeBottom ) && should_auto_scroll;

        if ( ! ( isInLeftEdge || isInRightEdge || isInTopEdge || isInBottomEdge ) ) {

            clearTimeout( this.timer );
            return;

        }

        this.adjustWindowScroll();

    }

    checkForWindowScroll() {

        clearTimeout( this.timer );

        if ( this.adjustWindowScroll() ) {

            this.timer = setTimeout( this.checkForWindowScroll, 30 );

        }

    };

    adjustWindowScroll() {
        var viewportWidth = document.documentElement.clientWidth;
        var viewportHeight = document.documentElement.clientHeight;

        var edgeSize = 50;

        var viewportX = this.event.clientX;
        var viewportY = this.event.clientY;

        var edgeTop = edgeSize + this.top_bar_height;
        var edgeLeft = edgeSize;
        var edgeBottom = ( viewportHeight - edgeSize );
        var edgeRight = ( viewportWidth - edgeSize );

        let should_auto_scroll = this.state.is_dragging || this.state.running_line_mouse_down || this.state.mouse_is_down
        var isInLeftEdge = ( viewportX < edgeLeft) && should_auto_scroll ;
        var isInRightEdge = ( viewportX > edgeRight ) && should_auto_scroll; 
        var isInTopEdge = ( viewportY < edgeTop) && should_auto_scroll && !this.state.dragged_top_button;
        var isInBottomEdge = ( viewportY > edgeBottom ) && should_auto_scroll;

        var documentWidth = Math.max(
            document.body.scrollWidth,
            document.body.offsetWidth,
            document.body.clientWidth,
            document.documentElement.scrollWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
        var documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.body.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );

        var maxScrollX = ( documentWidth - viewportWidth );
        var maxScrollY = ( documentHeight - viewportHeight );

        var currentScrollX = window.pageXOffset;
        var currentScrollY = window.pageYOffset;

        var canScrollUp = ( currentScrollY > 0 );
        var canScrollDown = ( currentScrollY < maxScrollY );
        var canScrollLeft = ( currentScrollX > 0 );
        var canScrollRight = ( currentScrollX < maxScrollX );

        var nextScrollX = currentScrollX;
        var nextScrollY = currentScrollY;

        var maxStep = 50;

        if ( isInLeftEdge && canScrollLeft ) {

            let intensity = ( ( edgeLeft - viewportX ) / edgeSize );

            nextScrollX = ( nextScrollX - ( maxStep * intensity ) );

        } else if ( isInRightEdge && canScrollRight ) {

            let intensity = ( ( viewportX - edgeRight ) / edgeSize );

            nextScrollX = ( nextScrollX + ( maxStep * intensity ) );

        }

        if ( isInTopEdge && canScrollUp ) {

            let intensity = ( ( edgeTop - viewportY ) / edgeSize );

            nextScrollY = ( nextScrollY - ( maxStep * intensity ) );

        } else if ( isInBottomEdge && canScrollDown ) {

            let intensity = ( ( viewportY - edgeBottom ) / edgeSize );

            nextScrollY = ( nextScrollY + ( maxStep * intensity ) );

        }

        nextScrollX = Math.max( 0, Math.min( maxScrollX, nextScrollX ) );
        nextScrollY = Math.max( 0, Math.min( maxScrollY, nextScrollY ) );

        if (
            ( nextScrollX !== currentScrollX ) ||
            ( nextScrollY !== currentScrollY )
            ) {
            window.scrollTo( nextScrollX, nextScrollY );
            return( true );

        } else {
            return( false );

        }

    }

    onChangeLaw(value){
        this.setState({text_content:value});
    }

    onChangeCitation(value){
        this.setState({citation_content:value});
    }

    onChangeNotes(value){
        this.setState({notes_content:value});
    }

    render() {
        return (
            
            <div className="all">
                <TopBar 
                    resetPlat={this.props.resetPlat}
                    undo={this.props.undo}
                    getUndoHistory={this.props.getUndoHistory}
                    addNode={this.addNode}
                    toggleEditMode={this.toggleEditMode} 
                    toggleCheckboxMode={this.toggleCheckboxMode} 
                    toggleCitationsMode={this.toggleCitationsMode}

                    toggleDrawer={this.toggleDrawer}

                    handleMouseUp={this.handleMouseUp}
                    globalState={() => this.state}
                    loadNodeMap={this.props.loadNodeMap}
                    getNodeMap={this.props.getNodeMap}
                    promoteButtonZIndex={this.props.promoteButtonZIndex}
                    draggedButton={this.draggedButton}
                    draggedButtonHighlighting={this.draggedButtonHighlighting}
                    DnDunhighlight={this.DnDunhighlight}
                />
                <div
                    className="workspace" 
                    id="workspace"
                    onMouseUp={this.handleMouseUp}
                    onMouseMove={this.runningLineMouseMove}
                    onMouseDown={this.dragBackgroundMouseDown}>
                        <div id="noderenderer">
                            <NodeRenderer 
                            savetoUndo={this.props.savetoUndo}
                            setDragging={this.setDragging}
                            getDragging={this.getDragging}
                            nestNode={this.props.nestNode}
                            unnestNode={this.props.unnestNode}
                            handleMousemove={this.handleMousemove}
                            resetUpdatedNodesMap={this.props.resetUpdatedNodesMap}
                            resetDeletedNodesMap={this.props.resetDeletedNodesMap}
                            deleted_nodes_map={this.props.deleted_nodes_map}
                            updated_nodes_map={this.props.updated_nodes_map}
                            is_dragging={this.state.is_dragging}
                            getNodeMap={this.props.getNodeMap}
                            updateNodeMap={this.props.updateNodeMap}
                            updateNode={this.props.updateNode}
                            
                            globalState={() => this.state}
                            handleMenu={this.handleMenu}

                            addNode={this.props.addNode}
                            
                            handleEdgeDrag={this.runningLineMouseDown}
                            
                            removeNode={this.props.removeNode}
                            promoteNodeZIndex={this.props.promoteNodeZIndex}
                        
                            updateEdge={this.props.updateEdge}
                            resetUpdateAllNodes={this.props.resetUpdateAllNodes}
                            resetUpdatedEdgesMap={this.props.resetUpdatedEdgesMap}
                            resetDeletedEdgesMap={this.props.resetDeletedEdgesMap}
                            resetDeleteAllEdges={this.props.resetDeleteAllEdges}
                            deleted_edges_map={this.props.deleted_edges_map}
                            updated_edges_map={this.props.updated_edges_map}
                            delete_all_edges={this.props.delete_all_edges}
                            update_all_edges={this.props.update_all_edges}
                            update_all_nodes={this.props.update_all_nodes}
                        /></div>
                        {this.state.running_line}      
                        <SideBar
                            toggleDrawer={this.toggleDrawer}
                            drawer_open = {this.state.drawer_open}
                            getNodeMap={this.props.getNodeMap}
                            text_content = {this.state.text_content}
                            citation_content = {this.state.citation_content}
                            notes_content = {this.state.notes_content}
                            onChangeLaw = {this.onChangeLaw}
                            onChangeCitation = {this.onChangeCitation}
                            addNode = {this.props.addNode}
                            onChangeNotes = {this.onChangeNotes}
                            updateNode={this.props.updateNode}

                        />
                </div>
            </div>
        );
    }
}

export default ScreenFrame;
