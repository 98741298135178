import {getUltimateParentofNodeID} from './utils';
import {NodeLineCanEnd, NodeNestIntoable} from './nodes/shared/NodeConsts';
import TextAndCitationNode from './nodes/elements/text/TextAndCitationNode';
import TextNode from './nodes/elements/text/TextNode';


function getAbsoluteOffsetFromBody( node_id, node_map )
{   
    let node = node_map.get(node_id);
    let node_DOM=node.myRef;
    let element=node_DOM.current;
    // finds the offset of el from the body or html element
    var y = 0, x = 0;
    do {
        y += element.offsetTop  || 0;
        x += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);

    return [x, y];
}

function isInCollapsed(node_id, node_map){
    let node=node_map.get(node_id);
    if(node.parent_id && getUltimateParentofNodeID(node.node_id, node_map).collapsed)
        return true;
    else
        return false;
}

function isTargetingNode(x, y, node_map, self_id = undefined, targeting_line = true, targeting_sidebar = false){
    /*
    Skip over the node being dragged (self_id)
    Skip over TextAndCitationNode and TextNode
    Skip over nodes whose parents are collapsed
    */
    let matching_id = 0;
    let max_zindex = 0;

    for (let node of node_map.values()){
        if(self_id && node.node_id === self_id){
            continue;
        }
        if(targeting_line){
            if(!NodeLineCanEnd(node.node_type))
                continue;
        }
        else
            if(!targeting_sidebar && !NodeNestIntoable(node.node_type))
                continue;
        
        if(isInCollapsed(node.node_id, node_map))
            continue;

        if([TextNode, TextAndCitationNode].includes(node.node_type))
            continue;
        let el_left = node.x;
        let el_top = node.y;

        let el_width = node.width;
        let el_height = node.height;

        if(
            el_left < x && el_left + el_width > x && 
            el_top < y && el_top + el_height > y){
                    if(node.z_index > max_zindex){  
                        max_zindex = node.z_index;
                        matching_id = node.node_id;
                    }
        }
    }
    return matching_id;
}

function isTargetingEdgeOfNode(x, y, node_map, node_id){
    if(!node_id)
        return;
    let node=node_map.get(node_id);
    let el_top = node.y;
    let el_height = node.height;

    let top_fifth = node.height/3;
    let bottom_fifth = top_fifth*2;

    //test top-fifth
    if(el_top < y && el_top + top_fifth > y)
        return "top_fifth";
    
    //test bottom-fifth
    if(el_top + bottom_fifth < y && el_top + el_height > y)
        return "bottom_fifth";

    return false;
}

function isTargetingSelf(droppedIntoId, draggedId, node_map){
    // if either is undefined, assume it is not targeting self
    if(!(droppedIntoId && draggedId))
        return false;

    // do not allow a parent to nest in a child
    let all_target_node_ids = [droppedIntoId];

    let seen = [];

    while(all_target_node_ids.length > 0){
        let target_id = all_target_node_ids.shift();

        if(seen.includes(target_id))
            continue

        if(target_id === draggedId)
            return true;
        
        seen.push(target_id);

        let target = node_map.get(target_id);

        if(target)
            if(target.parent_id)
                all_target_node_ids.push(target.parent_id);
    }

    return false;
    
}

export {getAbsoluteOffsetFromBody, isTargetingNode, isInCollapsed, isTargetingEdgeOfNode, isTargetingSelf};