import React from 'react';
import {
    Menu,
    TextArea,
    MenuItem,
    Intent,
} from "@blueprintjs/core";



import _ from 'lodash';
import { classToStringNodeType } from '../nodes/shared/NodeConsts';
import { Auth, Storage } from 'aws-amplify';


class SaveMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            save_file_text:""
        }
        this.serializeNode=this.serializeNode.bind(this);
        this.serializeNodeMap=this.serializeNodeMap.bind(this);
        this.savePlat=this.savePlat.bind(this);
        this.download=this.download.bind(this);
        this.handleSaveFileText=this.handleSaveFileText.bind(this);
    }
    
    serializeNode(node){
        let copy_node=_.cloneDeep(node);
        copy_node.myRef=undefined; //cutting out myRef because stringify throws circularity error if it's left in.  loading generates a new ref using React.createRef anyway.
        copy_node.childrenRef = undefined;
        let jsonNode = JSON.stringify(copy_node);

        jsonNode = JSON.parse(jsonNode);
    
        jsonNode.node_type = classToStringNodeType(node.node_type);
        return jsonNode;
    }

    serializeNodeMap(){
        let result = [];
    
        for(const [node_id, node] of this.props.getNodeMap()){
            result.push([node_id, this.serializeNode(node)]);
        }
    
        return JSON.stringify(result);
    }
    
    download(filename, data) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }
    
    savePlat(){
        let serializedData = this.serializeNodeMap();
        this.download(this.state.save_file_text+'.plat', serializedData);
    }

    async saveCloudPlat(){
        let info = await Auth.currentUserInfo();
        let serializedData = this.serializeNodeMap();
        
        Storage.put(info.attributes.email+"/"+this.state.save_file_text+'.plat',serializedData).then (result => {
        this.props.toaster.show({
            message:"Successfully saved to: /"+result.key,
            icon: "cloud-upload",
            intent: Intent.SUCCESS,
            timeout: 2000,
        });
    }
        ).catch (err => this.props.toaster.show({message:"Error saving",
        icon: "cloud-upload",
        intent: Intent.WARNING,
        timeout: 2000,}));
    }

    async saveSharedCloudPlat(){
        let serializedData = this.serializeNodeMap();
        
        Storage.put("shared/"+this.state.save_file_text+'.plat',serializedData).then (result => this.props.toaster.show({
            message:"Successfully saved to: /"+result.key,
            icon: "cloud-upload",
            intent: Intent.SUCCESS,
            timeout: 2000,
        })).catch (err => this.props.toaster.show({message:"Error saving",
        icon: "cloud-upload",
        intent: Intent.WARNING,
        timeout: 2000,}));
    }


    handleSaveFileText(text){
        this.setState({
            save_file_text: text
        })
    }
    


    render() { 
        return    (
            <Menu>
                
                <TextArea rows={1} id="save_textarea" small={true} placeholder="Enter filename" onChange={(e)=>this.handleSaveFileText(e.target.value)}/>
                <MenuItem 
                    icon="floppy-disk" 
                    text="Save Local Copy"
                    onClick={()=>this.savePlat()}
                />
                <MenuItem 
                    icon="cloud-upload"
                    text="Save to Private Cloud"
                    onClick={()=>this.saveCloudPlat()}
                />
                <MenuItem 
                    icon="cloud-upload"
                    text="Save to Shared Cloud"
                    onClick={()=>this.saveSharedCloudPlat()}
                />
                
            </Menu>
        );
    }
}
 
export default SaveMenu;







