import React from 'react';

import {
    Button
} from "@blueprintjs/core";
import { isNodeTopLevel} from '../utils';
import {isTargetingNode, isTargetingEdgeOfNode} from '../targeting_utils';
import { NodeContainerorSubtitle, NodeNestableFromTopbar } from '../nodes/shared/NodeConsts';

class DnDButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            client_x:0,
            client_y:0,
        }
        this.handleMouseDown=this.handleMouseDown.bind(this);
        this.trackMouse=this.trackMouse.bind(this);
        this.DnDhighlight=this.DnDhighlight.bind(this);
    }

    DnDhighlight(e, targetedNodeId, targetNodeIdTopBottom, node_map){
        let node = node_map.get(targetedNodeId);
        let dom_node = node.myRef.current;
        let children_node = null;
        
        if(node.childrenRef)
            children_node = node.childrenRef.current;

        let save_zIndex=dom_node.style.zIndex;
        let save_margin= dom_node.style.padding;
        let save_padding= children_node?children_node.style.padding:null;
        
        if(!isNodeTopLevel(node))
            switch(targetNodeIdTopBottom){
                case "top_fifth":
                    dom_node.style.paddingTop = "20px"; //top right bottom left
                    break;

                case "bottom_fifth":
                    dom_node.style.paddingBottom = "20px";
                    break;

                default:
                    children_node.style.paddingBottom = "20px";
                    break;
            }
        else
            children_node.style.paddingBottom = "20px";

        this.props.draggedButtonHighlighting(true, targetedNodeId, targetNodeIdTopBottom, save_zIndex, save_margin, save_padding);
    }

    trackMouse(e){
        if(NodeNestableFromTopbar(this.props.node_type)){
            //highlighting
            let node_map = this.props.getNodeMap();
            let targetedNodeId = isTargetingNode(e.pageX, e.pageY, node_map, undefined, false);
            let node = node_map.get(targetedNodeId);
            let targetNodeIdTopBottom = false;

            if(node && !NodeContainerorSubtitle(node.node_type))
                targetNodeIdTopBottom = isTargetingEdgeOfNode(e.pageX, e.pageY, node_map, targetedNodeId);

            if(targetedNodeId!== this.props.draggedButtonHighlighting(false).target_node_id || targetNodeIdTopBottom!==this.props.draggedButtonHighlighting(false).targetNodeIdTopBottom){
                //dehighlight old node
                this.props.DnDunhighlight();

                //highlight new node
                if(targetedNodeId){
                    this.DnDhighlight(e, targetedNodeId, targetNodeIdTopBottom, node_map);
                }
            } //end highlighting
        }

        this.setState({
          client_x: e.clientX,
          client_y: e.clientY,
        }, () => this.old_document_onmousemove_handler && this.old_document_onmousemove_handler(e));
    }
    handleMouseDown(e, dragged_top_button){
        this.old_document_onmousemove_handler = document.onmousemove;
        document.onmousemove = this.trackMouse;
        this.trackMouse(e);
        this.props.promoteButtonZIndex(dragged_top_button + "_button");
        this.props.draggedButton(dragged_top_button);
    }

    render() {
        let draggable_style = {
            position: "absolute",
            left: this.state.client_x -162.5 + "px",
            top: this.state.client_y + "px"
        }

        if(this.props.globalState().dragged_top_button !== this.props.nodeType){
            draggable_style = {
                position: "absolute",
                left: "-999px",
                top: "-999px"
            }
        }

        draggable_style.zIndex=9007199254740990;

        return ( 
            <div 
                className="top_bar_equal_space"
                id={this.props.nodeType + "_button"}
            >
                <Button 
                    className="top_bar_max_width" 
                    text={this.props.text}
                    icon={this.props.icon}                               
                    minimal={this.props.minimal}
                    intent={this.props.intent}
                    onMouseDown={(e) => this.handleMouseDown(e, this.props.nodeType)}
                    active={this.props.globalState().selected_top_button === this.props.nodeType}
                />
                <div
                    style={draggable_style}
                >
                    <div 
                        className={"container_box " + this.props.nodeType + "_frame fix_node_width"}
                    >
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }
}
 
DnDButton.defaultProps = {
    can_nest: true,
}
export default DnDButton;