import React, { Component } from 'react';
import HeaderNode from './nodes/elements/header/HeaderNode';
import ScreenFrame from './ScreenFrame';

import {getUltimateParentofNodeID} from './utils';

import {isTargetingSelf} from './targeting_utils';

import _ from 'lodash';
import {listChildrenofNodeIDbyLevel} from './utils';

import {NodeContainer, NodeContainerCanNestInto, NodeOnlyNestsTopLevelAndConvertsToHeader, NodeNestableFromTopbar, NodeOnlyNestsTopLevel, stringToClassNodeType} from './nodes/shared/NodeConsts';

import {EditorState} from 'draft-js';

class NodeStruct{
    constructor(node_id, parent_id=undefined, node_type=undefined, x=0, y=0, width=0, text="", citation="", deactivate_citation=false){
        this.node_id = node_id;
        this.node_type = node_type;

        this.collapsed = false;
        this.highlight = false;
        this.citation_enabled = !deactivate_citation;
        this.checked = "false";

        this.x = x;
        this.y = y;
        this.width = width;
        this.height = 0;
        this.z_index = 0;

        this.edges_from_nodes = [];
        this.edges_to_nodes = [];

        this.text = text;
        this.richtext = EditorState.createEmpty();
        this.citation = citation;

        this.parent_id = parent_id;
        this.child_ids = [];
        
        this.menu_disabled = true;
        this.over_sub = false;
        this.over_sub_override = false;

        this.myRef = React.createRef();
        this.childrenRef =  React.createRef();;
        this.draggable_key = 0;
    }
}

class NodeMapHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            node_map: new Map(),
            
            updated_nodes_map: [],
            deleted_nodes_map: [],

            updated_edges_map: [],
            deleted_edges_map: [],
            delete_all_edges: false,
            update_all_nodes: false,
            update_all_edges: false,

            current_z_index:0,

            history: [],
        };
        
        this.resetPlat=this.resetPlat.bind(this);
        this.savetoUndo = this.savetoUndo.bind(this);
        this.undo = this.undo.bind(this);
        this.getUndoHistory=this.getUndoHistory.bind(this);

        this.loadNodeMap = this.loadNodeMap.bind(this);

        this.getNodeMap = this.getNodeMap.bind(this);
        this.updateNodeMap = this.updateNodeMap.bind(this);
        
        //ZIndices
        this.promoteButtonZIndex=this.promoteButtonZIndex.bind(this);
        this.promoteNodeZIndex=this.promoteNodeZIndex.bind(this);
        this.setAllNodeZIndices=this.setAllNodeZIndices.bind(this);

        //nesting
        this.unnestNode=this.unnestNode.bind(this);
        this.nestNode=this.nestNode.bind(this);

        //adding/removing
        this.addNode=this.addNode.bind(this);
        this.addNodeOfType=this.addNodeOfType.bind(this);
        this.removeNode=this.removeNode.bind(this);

        this.addEdge=this.addEdge.bind(this);
        
        //edge rendering
        this.updateEdge = this.updateEdge.bind(this);
        this.resetUpdatedEdgesMap=this.resetUpdatedEdgesMap.bind(this);

        this.deleteEdge = this.deleteEdge.bind(this);
        this.resetDeletedEdgesMap=this.resetDeletedEdgesMap.bind(this);

        this.deleteAllEdges = this.deleteAllEdges.bind(this);
        this.resetDeleteAllEdges = this.resetDeleteAllEdges.bind(this);
        

        //node rendering
        this.updateNode = this.updateNode.bind(this);
        this.resetUpdatedNodesMap=this.resetUpdatedNodesMap.bind(this);

        this.updateAllNodes = this.updateAllNodes.bind(this);
        this.resetUpdateAllNodes = this.resetUpdateAllNodes.bind(this);
        
        this.deleteNode = this.deleteNode.bind(this);
        this.resetDeletedNodesMap=this.resetDeletedNodesMap.bind(this);

        
    }

    resetPlat(){
        console.log("resetPlat");
        this.setState({node_map:new Map(), history:[], current_z_index:0},
        ()=>{this.deleteAllEdges();this.updateAllNodes();});
    }

    getUndoHistory(){
     //   return _.cloneDeep(this.state.history);
    }

    savetoUndo(description){
      /*  let new_history = this.state.history;
        new_history.push({
            description,
            node_map:_.cloneDeep(this.state.node_map),
            current_z_index:this.state.current_z_index});
        
        this.setState({history:new_history});*/
    }

    undo(index = 0){
      /*  let new_history = _.cloneDeep(this.state.history);

        let previous_state;
        
        for(let i=0;i<index+1;i++)
            previous_state=new_history.pop();

        if(!previous_state)
            return;

        let new_node_map = previous_state.node_map;
        let new_current_z_index = previous_state.current_z_index;

        let update_node_ids = [];

        for(let node of new_node_map.values())
            if(!node.parent_id)
                update_node_ids.push(node.node_id);

        this.setState({
            node_map:new_node_map,
            current_z_index:new_current_z_index,
            history:new_history
        }, () => {this.deleteAllEdges(); this.updateAllNodes();
                this.updateEdge(update_node_ids);})*/
    }

    loadNodeMap(node_map, current_z_index, delete_nodes, update_nodes){
        console.log("loadNodeMap");
        let new_z_index;
        let new_node_map;
        [new_z_index, new_node_map] = this.setAllNodeZIndices(node_map);
        this.setState({
            current_z_index:new_z_index,
            node_map:new_node_map
        },  ()=>{
                this.deleteAllEdges();
                this.deleteNode(delete_nodes);
                this.updateNode(update_nodes);
                this.updateEdge(update_nodes);
            }
        );
 
    }

    promoteButtonZIndex(node_id){
        let zIndex = this.state.current_z_index;
        let dom_node = document.getElementById(node_id);
        dom_node.style.zIndex = zIndex+1;
        return;
    }

    promoteNodeZIndex(node_id){
        console.log("promoteNodeZIndex");

        let increment = 150;
        let max_increment_level = 1;
        let zIndex = this.state.current_z_index;
        let new_state=this.state;
        let node_map  = new_state.node_map;
        let node = node_map.get(node_id);
        let zIndexChanged = false;
        let parent_zIndex = 0;

        if(node && node.z_index<zIndex){
            let update_objects = listChildrenofNodeIDbyLevel(node_id, node_map);

            parent_zIndex = node.z_index = zIndex+increment;

            for(let child_object of update_objects){
                node_map.get(child_object.node_id).z_index = parent_zIndex+(increment*child_object.level);
                max_increment_level = Math.max(max_increment_level, child_object.level+1);
            }

            zIndexChanged=true;
        }

        if(zIndexChanged){
            new_state.current_z_index=zIndex+(increment*max_increment_level);
            this.setState({
                ...new_state
            }, () => {this.updateNode(node_id);});
        }
    }

    setAllNodeZIndices(old_node_map){
        let node_map = old_node_map;
        let max_z_index = 0;
        let increment = 150;

        for(let node of node_map.values()){
            let parent_zIndex = 0;
            let max_increment_level = 1;
            if(!node.parent_id){
                let child_nodes = listChildrenofNodeIDbyLevel(node.node_id, node_map);

                parent_zIndex = node_map.get(node.node_id).z_index = max_z_index+increment;
                max_z_index=Math.max(max_z_index, parent_zIndex);

                for(let child_node of child_nodes){
                    node_map.get(child_node.node_id).z_index=parent_zIndex+(increment*child_node.level);
                    max_z_index=Math.max(max_z_index, parent_zIndex+(increment*child_node.level));
                    max_increment_level = Math.max(max_increment_level, child_node.level + 1);
                }
            }
        }
        console.log("new max_z_index is "+max_z_index);
        return [max_z_index, node_map];
    }

    
    nestNode(droppedIntoId, draggedId, TopBottom){ //TopBottom can be "top_fifth" "bottom_fifth" or false
        /*Scenarios:
            #1: Dragged into null/undefined - do nothing
            #2: Dragged into itself or a child of itself - do nothing
            #3: Dragging a container into a container - convert to header
            #3A: Dragging a container into anything other than a container - do nothing
            #4: Dragged into its own parent - reset position -> updateXY
            #5: Dragged into another node [from a top-level position] -> updateXY
            #5A: Dragged into another node from a nested position -- remove from old parent; update old parent -> updateXY*/

        console.log("nestNode");

        let node_map = /*_.cloneDeep*/(this.state.node_map);
        let draggedNode = node_map.get(draggedId);
        let droppedIntoNode = node_map.get(droppedIntoId);
        let draggedFromId = draggedNode.parent_id;
        let draggedFromNode = node_map.get(draggedFromId);
        let updateNodeIds = [droppedIntoId];
        let sibling_node_id = 0;
        let sibling_node_order = 0;

        if(!droppedIntoNode) //screens out scenario 1: Dragged into null/undefined - do nothing
            return;

        if(isTargetingSelf(droppedIntoNode, draggedId, node_map)) //screens out scenario 2: Dragged into itself or a child of itself - do nothing
            return;

        if(NodeOnlyNestsTopLevel(draggedNode.node_type)) //changes target if dragging one container into another (header conversion)
            if(NodeContainerCanNestInto(getUltimateParentofNodeID(droppedIntoId,node_map).node_type))
                droppedIntoId = (droppedIntoNode=getUltimateParentofNodeID(droppedIntoId,node_map)).node_id;  
            else
                return;

        if(droppedIntoNode.parent_id) //if 'highlighting' moved the node out of the way, target its parent instead
            if(TopBottom){
                sibling_node_id=droppedIntoId;
                droppedIntoNode=node_map.get(droppedIntoNode.parent_id);
                droppedIntoId=droppedIntoNode.node_id;
            }

        if(draggedFromId){
            if(droppedIntoId === draggedFromId) { //scenario 4: Dragged into its own parent - reset position -> updateXY
                draggedNode.draggable_key+=" ";
                if(!sibling_node_id){
                    this.setState({node_map}, () => {this.updateNode([draggedFromId,draggedId]);});
                    return;}
            }
            
            draggedFromNode.child_ids = _.filter(draggedFromNode.child_ids, (x)=> x !== draggedId); //scenario 5A
            updateNodeIds.push(draggedFromId);
        }

        if(NodeOnlyNestsTopLevelAndConvertsToHeader(draggedNode.node_type)) // scenario 3 -> scenario 5
            draggedNode.node_type = HeaderNode;

        draggedNode.parent_id = droppedIntoId; //scenario 5
        draggedNode.x=0;
        draggedNode.y=0;

        if(sibling_node_id){
            sibling_node_order = droppedIntoNode.child_ids.indexOf(sibling_node_id);
            if(TopBottom === "top_fifth")
                droppedIntoNode.child_ids.splice(sibling_node_order, 0, draggedId);
            if(TopBottom === "bottom_fifth")
                droppedIntoNode.child_ids.splice(sibling_node_order+1, 0, draggedId);
        }
        else
            droppedIntoNode.child_ids.push(draggedId);
        
        this.setState({node_map}, () => {this.promoteNodeZIndex(getUltimateParentofNodeID(droppedIntoId, node_map).node_id); this.deleteNode(draggedId); this.updateNode(updateNodeIds);this.updateEdge(updateNodeIds);})
    }

    unnestNode(node_id){
        console.log("unnestNode");
        let node_map = /*_.cloneDeep*/(this.state.node_map);
        let node = node_map.get(node_id);

        // remove from parent
        if(node.parent_id){
            let parent = node_map.get(node.parent_id);
            parent.child_ids = _.filter(parent.child_ids, (id) => id !== node_id);
            
            if(node.node_type === HeaderNode && NodeContainer(getUltimateParentofNodeID(node_id, node_map).node_type))
                node.node_type = getUltimateParentofNodeID(node_id, node_map).node_type;

            node.parent_id = undefined;
            this.setState({node_map}, () => {this.updateNode([node_id, parent.node_id]); this.updateEdge([node_id, parent.node_id]);})
        }
    }

    getNodeMap(){
        return this.state.node_map;
    }

    updateNodeMap(node_map, post_update=()=>{}){
        this.setState({node_map}, () => post_update());
    }

    addNode(parent_id, node_type, start_node_id=undefined, x=0,y=0, text="", citation="", deactivate_citation = false, sibling_node_id = null, topbottom = null){
        x=Math.max(0,x);
        y=Math.max(0,y);

        let node_map = this.state.node_map;
        let new_node_id = parseInt(Math.floor(Math.random() * 100000));

        let width = 325;

        if(!NodeContainer(node_type))
            width = 300;

        if(start_node_id){
            let start_node=node_map.get(start_node_id);
            if(start_node){
                if(!x)
                    x=start_node.x;
                if(!y)
                    y=15 + start_node.y+start_node.height;
            }
        }

        let new_node = new NodeStruct(
                                        new_node_id, 
                                        parent_id, 
                                        node_type, 
                                        x, 
                                        y, 
                                        width,
                                        text,
                                        citation,
                                        deactivate_citation
                                    );

        new_node.z_index = this.state.current_z_index + 150;
        node_map.set(new_node_id, new_node);

        if(parent_id){
            let parent_node = node_map.get(parent_id);

            let sibling_node_order = null;

            if(sibling_node_id){
                sibling_node_order = parent_node.child_ids.indexOf(sibling_node_id);
                if(topbottom === "top_fifth")
                    parent_node.child_ids.splice(sibling_node_order, 0, new_node_id);
                if(topbottom === "bottom_fifth")
                    parent_node.child_ids.splice(sibling_node_order+1, 0, new_node_id);
            }
            else
                parent_node.child_ids.push(new_node_id);
        }

        if(start_node_id){
            this.addEdge(start_node_id, new_node_id, node_map);
            this.setState({
                node_map,
                current_z_index: this.state.current_z_index + 150,
            }, () => {this.updateNode(new_node_id); this.updateEdge(new_node_id);})
        }
        else
            this.setState({
                node_map,
                current_z_index: this.state.current_z_index + 150,
            }, () => {if(parent_id) this.promoteNodeZIndex(getUltimateParentofNodeID(parent_id, node_map).node_id); else this.updateNode(new_node_id);})
        return new_node_id;
    }

    addNodeOfType(node_type, x, y, targetedNodeId, sibling_node_id, topbottom){
        let class_node_type=stringToClassNodeType(node_type);
        
        if(targetedNodeId && NodeNestableFromTopbar(class_node_type))
            this.addNode(targetedNodeId, stringToClassNodeType(node_type), false, 0, 0, "", "", false, sibling_node_id, topbottom);
        else
            this.addNode(null, stringToClassNodeType(node_type), false, x - 162, y);
    }

    async removeNode(node_id){
         console.log("removeNode");
        let new_state = /*_.cloneDeep*/(this.state);
        let node = new_state.node_map.get(node_id);
        let update_nodes = [];
        let delete_nodes = [];
        let delete_node_edges =[];

        // remove edges going to the removed node from their start nodes
        for(let start_node_id of node.edges_from_nodes){
            let start_node = new_state.node_map.get(start_node_id);
            start_node.edges_to_nodes = _.filter(start_node.edges_to_nodes, (x) => x !== node_id);
            delete_node_edges.push(start_node_id+" "+node_id); //edges running to the removed node
        }

        // remove edges coming from the removed node from their end nodes
        for(let end_node_id of node.edges_to_nodes){
            let end_node = new_state.node_map.get(end_node_id);
            end_node.edges_from_nodes = _.filter(end_node.edges_from_nodes, (x) => x !== node_id);
            delete_node_edges.push(node_id+" "+end_node_id);//edges running from the removed node
        }

        // remove id from parent's nested children
        if(node.parent_id){
            let parent = new_state.node_map.get(node.parent_id);
            parent.child_ids = _.filter(parent.child_ids, (x) => x !== node_id);
            update_nodes.push(parent.node_id);
        }
 
        function recursive_child_killer(node_id2){
            let node1 = new_state.node_map.get(node_id2);
            for(let nested_id of node1.child_ids)
            {
                let nested_node = new_state.node_map.get(nested_id);
                recursive_child_killer(nested_id);

                for(let start_node_id of nested_node.edges_from_nodes){
                    let start_node = new_state.node_map.get(start_node_id);
                    start_node.edges_to_nodes = _.filter(start_node.edges_to_nodes, (x) => x !== nested_id);
                    delete_node_edges.push(start_node_id+" "+nested_id);
                }
        
                // remove edge from descendant nodes
                for(let end_node_id of nested_node.edges_to_nodes){
                    let end_node = new_state.node_map.get(end_node_id);
                    end_node.edges_from_nodes = _.filter(end_node.edges_from_nodes, (x) => x !== nested_id);
                    delete_node_edges.push(nested_id+" "+end_node_id);
                }
        
                new_state.node_map.delete(nested_id);
                delete_nodes.push(nested_id);
            }
        }
        recursive_child_killer(node_id);

        new_state.node_map.delete(node_id);
        delete_nodes.push(node_id);
        console.log("delete_node_edges "+delete_node_edges);
        this.setState({
            ...new_state
        },() => { this.deleteNode(delete_nodes); this.deleteEdge(delete_node_edges); this.updateNode(update_nodes);this.updateEdge(update_nodes);});
    }

    updateNode(update_node){
        console.log("updateNode "+update_node);
        let updated_nodes_map = /*_.cloneDeep*/(this.state.updated_nodes_map);
        if(Array.isArray(update_node))
            updated_nodes_map.push.apply(updated_nodes_map,update_node);
        else
            updated_nodes_map.push(update_node); 
        this.setState({
            updated_nodes_map
        });
    }

    deleteNode(delete_node){
        console.log("deleteNode");
        let deleted_nodes_map = /*_.cloneDeep*/(this.state.deleted_nodes_map);
        if(Array.isArray(delete_node))
                deleted_nodes_map.push.apply(deleted_nodes_map,delete_node);
        else
            if(!deleted_nodes_map.includes(delete_node))
                deleted_nodes_map.push(delete_node);
        this.setState({
            deleted_nodes_map
        })
    }

    resetUpdatedNodesMap(addressed_node_ids = []){
        if(addressed_node_ids.length){
            let updated_nodes_map=this.state.updated_nodes_map;
            for(let node_id in addressed_node_ids)
                updated_nodes_map=_.filter(updated_nodes_map, (x) => x === node_id);
            this.setState({updated_nodes_map});
        }
    }
    resetDeletedNodesMap(deleted_node_ids = []){
        if(deleted_node_ids.length){
            let deleted_nodes_map=this.state.deleted_nodes_map;
            for(let node_id in deleted_nodes_map)
                deleted_nodes_map=_.filter(deleted_nodes_map, (x) => x === node_id);
            this.setState({deleted_nodes_map});
        }
    }

    addEdge(from_node_id, to_node_id, cloned_node_map=undefined){
        console.log("addEdge");
        let delete_edge_key=[];
        let node_map = cloned_node_map;

        if(!node_map)
            node_map = /*_.cloneDeep*/(this.state.node_map);

        let start_node = node_map.get(from_node_id);
        let to_node = node_map.get(to_node_id);

        if(start_node.edges_to_nodes.includes(to_node_id)){
            start_node.edges_to_nodes = _.filter(start_node.edges_to_nodes, (x) => x !== to_node_id);
            delete_edge_key.push(from_node_id+" "+to_node_id);
        }
        else
            start_node.edges_to_nodes.push(to_node_id);

        if(to_node.edges_from_nodes.includes(from_node_id))
            to_node.edges_from_nodes = _.filter(to_node.edges_from_nodes, (x) => x !== from_node_id);
        else
            to_node.edges_from_nodes.push(from_node_id);

        //make sure if adding edge, there isn't still another one going the other way between the two nodes
        if(to_node.edges_to_nodes.includes(from_node_id)){
            to_node.edges_to_nodes=_.filter(to_node.edges_to_nodes, (x) => x!== from_node_id);
            delete_edge_key.push(to_node_id+" "+from_node_id);
        }

        if(start_node.edges_from_nodes.includes(to_node_id))
            start_node.edges_from_nodes=_.filter(start_node.edges_from_nodes, (x) => x!== to_node_id);

        if(!cloned_node_map)
            this.setState({
                node_map
            }, () => {this.updateEdge(from_node_id);this.deleteEdge(delete_edge_key);})
    }


    resetUpdatedEdgesMap(addressed_node_ids = []){
        if(addressed_node_ids.length){
            let updated_edges_map=this.state.updated_edges_map;
            for(let node_id in addressed_node_ids)
                updated_edges_map=_.filter(updated_edges_map, (x) => x === node_id);
            this.setState({updated_edges_map});
        }
    }
    resetDeletedEdgesMap(deleted_node_ids = []){
        console.log("resetDeletedEdgesMap");
        if(deleted_node_ids.length){
            let deleted_edges_map=this.state.deleted_edges_map;
            for(let node_id in deleted_edges_map)
                deleted_edges_map=_.filter(deleted_edges_map, (x) => x === node_id);
            this.setState({deleted_edges_map});
        }
    }

    updateEdge(update_node){
        let updated_edges_map = /*_.cloneDeep*/(this.state.updated_edges_map);
        if(Array.isArray(update_node))
            updated_edges_map.push.apply(updated_edges_map, update_node);
        else 
            updated_edges_map.push(parseInt(update_node));
        this.setState({
            updated_edges_map
        })
    }

    deleteEdge(delete_edge_key){
        console.log("deleteEdge");
        let deleted_edges_map = /*_.cloneDeep*/(this.state.deleted_edges_map);
        if(Array.isArray(delete_edge_key)) {
            for(let key of delete_edge_key)
                deleted_edges_map.push(key);
            console.log("deleteEdge deleted_edges_map "+deleted_edges_map);
            this.setState({
                deleted_edges_map
            })

        }
        else {
            if(!deleted_edges_map.includes(delete_edge_key))
                deleted_edges_map.push(delete_edge_key);
            this.setState({
                deleted_edges_map
            })
        }
    }

    deleteAllEdges(){
        console.log("deleteAllEdges");
        this.setState({delete_all_edges:true}, ()=>this.deleteEdge());
    }

    resetDeleteAllEdges(){
        console.log("resetDeleteAllEdges");
        this.setState({delete_all_edges:false});
    }

    updateAllNodes(){
        this.setState({update_all_nodes:true});
    }

    resetUpdateAllNodes(){
        this.setState({update_all_nodes:false});

    }

    updateAllEdges(){
        this.setState({update_all_edges:true});
    }

    resetUpdateAllEdges(){
        this.setState({update_all_edges:false});

    }


    render() {
        return (
            
            <ScreenFrame 
                resetPlat={this.resetPlat}
                savetoUndo={this.savetoUndo}
                undo={this.undo}
                getUndoHistory={this.getUndoHistory}

                updated_nodes_map={this.state.updated_nodes_map}
                deleted_nodes_map={this.state.deleted_nodes_map}
                updated_edges_map={this.state.updated_edges_map}
                deleted_edges_map={this.state.deleted_edges_map}
                delete_all_edges={this.state.delete_all_edges}
                update_all_edges={this.state.update_all_edges}
                update_all_nodes={this.state.update_all_nodes}

                loadNodeMap={this.loadNodeMap}
                getNodeMap={this.getNodeMap}
                updateNodeMap={this.updateNodeMap}
                promoteButtonZIndex={this.promoteButtonZIndex}
                promoteNodeZIndex={this.promoteNodeZIndex}
                updateAllNodes={this.updateAllNodes}
                resetUpdateAllNodes={this.resetUpdateAllNodes}

                unnestNode={this.unnestNode}
                nestNode={this.nestNode}

                addNode={this.addNode}
                addNodeOfType={this.addNodeOfType}
                removeNode={this.removeNode}
                addEdge={this.addEdge}
                
                resetUpdatedEdgesMap={this.resetUpdatedEdgesMap}
                resetDeletedEdgesMap={this.resetDeletedEdgesMap}
                updateEdge={this.updateEdge}
                deleteEdge={this.deleteEdge} 
                deleteAllEdges={this.deleteAllEdges}
                resetDeleteAllEdges={this.resetDeleteAllEdges}
                updateAllEdges={this.updateAllEdges}
                resetUpdateAllEdges={this.resetUpdateAllEdges}
        
                resetUpdatedNodesMap={this.resetUpdatedNodesMap}
                resetDeletedNodesMap={this.resetDeletedNodesMap}
                updateNode={this.updateNode}
                deleteNode={this.deleteNode}
                />
        );
    }
}

export default NodeMapHolder;
