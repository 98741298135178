import React from 'react';
import {
    Button,
    Popover, 
    Position,
    Navbar,
    Menu,
    MenuItem,
    Toaster,
    Divider
} from "@blueprintjs/core";

import DnDButton from './DnDButton';
import LoadMenu from './LoadMenu';
import SaveMenu from './SaveMenu';
import DeleteMenu from './DeleteMenu';
import { classToStringNodeType, NodeAltTextFromType, NodeContainerAppearsInTopbar, NodeElementAppearsInTopbar, NodeIconFromType} from '../nodes/shared/NodeConsts';
import { Auth } from 'aws-amplify';


class TopBar extends React.Component {
    toaster;
    refHandlers = {
        toaster: (ref) => (this.toaster = ref),
    };
    render() { 

        let fileMenu=(
            <>
            <Menu>
                <MenuItem 
                    className=""
                    icon="new-object"
                    text="New"
                    onClick={()=>this.props.resetPlat()}
                />
                <MenuItem 
                    className=""
                    icon="folder-open" 
                    text="Load"
                >
                    <LoadMenu getNodeMap={this.props.getNodeMap} loadNodeMap={this.props.loadNodeMap} />
                </MenuItem>
                <MenuItem
                    className=""
                    icon="add-to-folder" 
                    text="Save" 
                >
                    <SaveMenu getNodeMap={this.props.getNodeMap} handleSaveFileText={this.props.handleSaveFileText} toaster={this.toaster}/>
                </MenuItem>
                <MenuItem
                    className=""
                    icon="delete"
                    text="Delete Cloud File" 
                >
                    <DeleteMenu  toaster={this.toaster}/>
                </MenuItem>
                <MenuItem
                    className=""
                    icon="print"
                    text="Print"
                    onClick={()=>
                            
                    setTimeout(() => {window.print()}, 100)}
                >
                </MenuItem>
                <MenuItem
                    className=""
                    icon="log-out"
                    text="Log out" 
                    onClick={()=>{Auth.signOut(); window.location.reload(false); }}
                />
                </Menu>
            </>
        );

        /*let undoMenu=(
            <Menu>
            {this.props.getUndoHistory().reverse().map(
                (history_item, index)=>
                    <MenuItem text={index+". "+history_item.description}
                    onClick={()=>this.props.undo(index)}></MenuItem>
            )}
            </Menu>
        );*/

        return ( 
            <Navbar
                fixedToTop={true}
                className="top_bar_nav noprint"
            >
                <Toaster ref={this.refHandlers.toaster} />
                <div 
                    className="top_bar_main"
                    id="top_bar"
                    onMouseUp={this.props.handleMouseUp}
                >
                    <div className="top_bar_file_ops">
                        <div className="top_bar_file_ops_files">

                            <Popover content={fileMenu} autoFocus={false} position={Position.BOTTOM_LEFT} modifiers={{preventOverflow:true}}>
                                <div className="top_bar_equal_space">
                                    <Button 
                                        className="top_bar_max_width"
                                        icon="add-to-folder" 
                                        text="File"
                                        minimal={true}
                                    />
                                </div>
                            </Popover>
                            {/*<Popover content={undoMenu} position={Position.BOTTOM_RIGHT}>
                                <div className="top_bar_equal_space">
                                    <Button 
                                        className="top_bar_max_width"
                                        icon="undo"
                                        text="Undo"
                                        minimal={true}
                                    />
                                </div>
                            </Popover>*/}
                            <Popover>
                                <div className="top_bar_equal_space">
                                    <Button
                                        className="top_bar_max_width"
                                        icon="menu" 
                                        text="Sidebar" 
                                        minimal={true}
                                        onClick={()=>this.props.toggleDrawer()}
                                    />
                                </div>
                            </Popover>
                        </div>
                        
                    </div>
                    <div className="top_bar_modes">

                        <div className="top_bar_modes_modes">
                            <div className="top_bar_equal_space">
                                <Button
                                    className="top_bar_max_width" 
                                    text="Edit"
                                    icon="edit"
                                    minimal={true}
                                    active={this.props.globalState().edit_mode}
                                    onClick={this.props.toggleEditMode}
                                />
                            </div>
                            <div className="top_bar_equal_space">
                                <Button
                                    className="top_bar_max_width" 
                                    text="Checkboxes"
                                    icon="symbol-square"
                                    minimal={true}
                                    active={this.props.globalState().checkbox_mode}
                                    onClick={this.props.toggleCheckboxMode}
                                />
                            </div>
                            <div className="top_bar_equal_space">
                                <Button 
                                    className="top_bar_max_width" 
                                    text="Citations"
                                    icon="citation"
                                    minimal={true}
                                    active={this.props.globalState().citations_mode}
                                    onClick={this.props.toggleCitationsMode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="top_bar_containers">
                        <div className="top_bar_containers_main">
                        
                            {NodeContainerAppearsInTopbar().map(
                                (node_type) =>
                                    <DnDButton 
                                    text={NodeAltTextFromType(node_type)}
                                    key={"DnDButton"+NodeAltTextFromType(node_type)}
                                    icon={<NodeIconFromType node_type={node_type} height="12px" width="12px"/>}                                
                                    minimal={true}
                                    node_type={node_type}
                                    nodeType={classToStringNodeType(node_type)}
                                    promoteButtonZIndex={this.props.promoteButtonZIndex}
                                    globalState={this.props.globalState}
                                    draggedButton={this.props.draggedButton}
                                    draggedButtonHighlighting={this.props.draggedButtonHighlighting}
                                    DnDunhighlight={this.props.DnDunhighlight}
                                    //can_nest={false}
                                    getNodeMap={this.props.getNodeMap}
                                />      
                            )}
                        </div>
                        <Divider/>
                        <div className="top_bar_containers_secondary">
                            {NodeElementAppearsInTopbar().map(
                                (node_type) =>
                                    <DnDButton
                                    text={NodeAltTextFromType(node_type)}
                                    key={"DnDButton"+NodeAltTextFromType(node_type)}
                                    icon={<NodeIconFromType node_type={node_type} height="12px" width="12px"/>}      
                                    minimal={true}
                                    node_type={node_type}
                                    nodeType={classToStringNodeType(node_type)}
                                    promoteButtonZIndex={this.props.promoteButtonZIndex}
                                    globalState={this.props.globalState}
                                    draggedButton={this.props.draggedButton}
                                    draggedButtonHighlighting={this.props.draggedButtonHighlighting}
                                    DnDunhighlight={this.props.DnDunhighlight}
                                    getNodeMap={this.props.getNodeMap}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </Navbar>
        );
    }
}
 
export default TopBar;