import React from 'react';
import {Checkbox} from '@blueprintjs/core';

class TripleCheckbox extends React.Component {
    handleCheckbox(node_map, node_id){
        console.log("handleCheckbox");
        let new_node_map = /*_.cloneDeep*/(node_map);
        let node = new_node_map.get(node_id);
        
        switch(node.checked){
            case "true":
                node.checked="indeterminate";
                break;
            case "indeterminate":
                node.checked="false";
                break;
            default:
                node.checked="true";
                break;
        }
        this.props.updateNodeMap(new_node_map,()=>{this.props.updateNode(node_id);})
    }
    checkedStatus(node){
        if(node.checked==="true")
            return true;
        else
            return false;
    }
    indeterminateStatus(node){
        if(node.checked==="indeterminate")
            return true;
        else
            return false;
    }
    render(){
        let node_map = this.props.node_map;
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);

        return (
            <Checkbox
                style={{marginLeft:"2px", marginBottom:"2px"}}
                id={"checkbox_"+node_id}
                onClick={()=>this.handleCheckbox(node_map, node_id)}
                checked={this.checkedStatus(node)}
                indeterminate={this.indeterminateStatus(node)}
            />
        );
    }
}

export default TripleCheckbox;