import React, { Component } from 'react';
import {getUltimateParentofNodeID} from '../utils'

import {isTargetingNode} from '../targeting_utils';

import {getColorFromType} from '../nodes/shared/NodeConsts';

class Nub extends Component {
    render() {
        
        const calculateNubZIndex = (node) => {
            return node.z_index+1;
        }

        const calculateNubD = (x1, y1, pos) => {

            let d = "M";
            let width = 5;
            let offset = width/2; //half the width of the semicircle -- offset to center the nub

            switch (pos) {
                case "bottom":
                    d+=(x1-offset)+" "+y1+" a1 1 0 0 0 "+width+" 0";
                    break;
                case "top":
                    d+=(x1-offset)+" "+y1+" a1 1 0 0 1 "+width+" 0";
                    break;
                case "right":
                    d+=x1+" "+(y1-offset)+" a1 1 0 0 1 0 "+width;
                    break;
                case "left":
                    d+=x1+" "+(y1-offset)+" a1 1 0 0 0 0 "+width;
                    break;
                default:
                    break;
            }
            return d;
        }
        
        return (
            <div style={{position:"relative", zIndex:calculateNubZIndex(this.props.node)} }>
                <svg className="edges">
                    <path d={calculateNubD(this.props.x,this.props.y,this.props.position)} fill={this.props.color} />
                </svg>
            </div>
        );
    }
}

class Edge extends Component {

    render() {

        const isLastofLast = (node) => {
            
            let node_map = this.props.getNodeMap();

            let is_last = true;
            let i_node = node;

            while(i_node.parent_id){
                let i_parent = node_map.get (i_node.parent_id);
                if(i_parent.child_ids[i_parent.child_ids.length - 1] !== i_node.node_id){
                    is_last = false;
                    break;
                }
                i_node = i_parent;
            }
            return is_last;
        }

        const calculateEdgeCoords = (start_node, end_node) => {

            let node_map = this.props.getNodeMap();

            if(start_node.parent_id && getUltimateParentofNodeID(start_node.node_id, node_map).collapsed)
                start_node = getUltimateParentofNodeID(start_node.node_id, node_map);
            
            if(end_node.parent_id && getUltimateParentofNodeID(end_node.node_id, node_map).collapsed)
                end_node = getUltimateParentofNodeID(end_node.node_id, node_map);         

            
            let y1 = start_node.y;
            let x1 = start_node.x;
            let start_node_width = start_node.width;
            let start_node_height = start_node.height;                        

            let y2 = end_node.y;
            let x2 = end_node.x;
            let end_node_width = end_node.width;
            let end_node_height = end_node.height;
    
            //assume parent is above child and overlaps at least somewhat
            let line_x1 = x1 + start_node_width/2;
            let line_y1 = y1 + start_node_height; //bottom middle of parent
            let line_x2 = x2 + end_node_width/2;
            let line_y2 = y2; //top middle of child
            let parent_pos = "bottom";
            let child_pos = "top";
        
            if (x2 >= x1 + start_node_width){ //parent is fully to the left of child
                line_x1 = x1 + start_node_width;
                line_y1 = y1 + start_node_height/2; //right middle of parent
                line_x2 = x2;
                line_y2 = y2 + end_node_height /2; //left middle of child
                parent_pos="right";
                child_pos="left";
            } else if (x2 + end_node_width <= x1){ // parent is fully to the right of the child
                line_x1 = x1;
                line_y1 = y1 + start_node_height/2; //left middle of parent
                line_x2 = x2 + end_node_width;
                line_y2 = y2 + end_node_height / 2; //right middle of child
                parent_pos="left";
                child_pos="right";
            } else if (x2 + end_node_width >= x1 && x2 <= x1 + start_node_width && y2 < y1){ // parent overlaps at least somewhat and is somewhat below child
                line_x1 = x1 + start_node_width/2;
                line_y1 = y1; //top middle of parent
                line_x2 = x2 + end_node_width / 2;
                line_y2 = y2 + end_node_height; //bottom middle of child
                parent_pos="top";
                child_pos="bottom";
            }
        
            if(
                (end_node.parent_id && (child_pos === "top")) || //no lines to the top of nested nodes
                (end_node.parent_id && !isLastofLast(end_node) && (child_pos === "bottom")) //no lines to the bottom of nested nodes, unless it's the last-last nested node
            )
            {
                if(x1+(start_node_width/2) < x2 + (end_node_width/2)){
                    line_x2 = x2;
                    line_y2 = y2 + end_node_height /2; //left middle of child
                    child_pos="left";
                } else {
                    line_x2 = x2 + end_node_width;
                    line_y2 = y2 + end_node_height / 2; //right middle of child
                    child_pos="right";
                }
            }
            return {x1: line_x1, x2: line_x2, parent_pos, y1: line_y1, y2: line_y2, child_pos};
        }
        
        const calculateEdgeZIndex = (start_node, end_node) => {
            return Math.max(start_node.z_index, end_node.z_index);
        }

        let coords = calculateEdgeCoords(this.props.start_node, this.props.end_node);
        if(!coords) return null;
        let {x1, x2, y1, y2, parent_pos, child_pos} = coords;

        let display_line = true;

        //this lets lines disappear when they start and end entirely within a single node
        if(this.props.start_node.z_index > this.props.end_node.z_index){
            let end_target_node = isTargetingNode(x2,y2,this.props.getNodeMap());
            if(end_target_node){
                let ultimate_parent_node = getUltimateParentofNodeID(end_target_node, this.props.getNodeMap());
                let ultimate_start_node = getUltimateParentofNodeID(this.props.start_node.node_id, this.props.getNodeMap());
                if(ultimate_parent_node.node_id === ultimate_start_node.node_id)
                    display_line = false;
            }
        }
        else {
            let start_target_node = isTargetingNode(x1,y1,this.props.getNodeMap());
            if(start_target_node){
                let ultimate_parent_node = getUltimateParentofNodeID(start_target_node, this.props.getNodeMap());
                let ultimate_end_node = getUltimateParentofNodeID(this.props.end_node.node_id, this.props.getNodeMap());
                if(ultimate_parent_node.node_id === ultimate_end_node.node_id)
                    display_line = false;
            }
        }
        if(getUltimateParentofNodeID(this.props.start_node.node_id, this.props.getNodeMap()) === getUltimateParentofNodeID(this.props.end_node.node_id, this.props.getNodeMap()))
            display_line = false;

        return (
        <div key={this.props.end_node.node_id + " " + this.props.start_node.node_id} className="edges">
            
            <Nub node={this.props.start_node} color={getColorFromType(this.props.start_node.node_type, this.props.start_node.node_id, this.props.getNodeMap())} x={x1} y={y1} position={parent_pos} />

            <div style={{position:"relative", zIndex:calculateEdgeZIndex(this.props.start_node, this.props.end_node)} }>
                <svg className="edges">
                {
                    display_line &&
                    <line 
                    key={this.props.end_node.node_id + " " + this.props.start_node.node_id}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={getColorFromType(this.props.start_node.node_type, this.props.start_node.node_id, this.props.getNodeMap())}
                    strokeWidth={"1.5px"}
                    />
                }
                </svg>
            </div>

            <Nub node={this.props.end_node} color={getColorFromType(this.props.start_node.node_type, this.props.start_node.node_id, this.props.getNodeMap())} x={x2} y={y2} position={child_pos} />
        </div>
        );
    }
}

export default Edge;
