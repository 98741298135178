/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0914ccdf-1894-4765-bf33-401de70c6586",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hDa3PpmdK",
    "aws_user_pools_web_client_id": "262ak1a5ev7dtfo5s9cgim2q9m",
    "oauth": {},
    "aws_user_files_s3_bucket": "legistrator29b93b445e0642019b75c21fc37d3a9e211258-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ok66cmrqrbaqtgtkt4m7si5iaq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kye4xfcflbedtp5ptomyhttewm",
    "aws_mobile_analytics_app_id": "d3b8bdd4508f43c2a221a165cdc649ef",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
