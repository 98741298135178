import React from 'react';

import { Switch, Drawer, Tab, Tabs, Tooltip, Classes } from "@blueprintjs/core";

import { EditableText, Button, Tree, ButtonGroup, AnchorButton, Divider} from "@blueprintjs/core";

import { getUltimateParentofNodeID} from '../utils';
import HeaderNode from '../nodes/elements/header/HeaderNode';
import { isTargetingNode } from '../targeting_utils';
import { getColorFromType, NodeIconFromType, NodeNestIntoable, NodeAltTextFromType, NodeContainerAppearsInTopbar, NodeElementAppearsInTopbar, NodeNestableFromTopbar} from '../nodes/shared/NodeConsts';

import Cheatsheet from './cheatsheet.png';

class HelpTab extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    render(){
        return (
            <>
                <div class="cheatsheet">
                    <br/>
                    <img alt="Cheatsheet" src={Cheatsheet}></img>
                </div>
            </>
        );
    }
}

class NotesTab extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    render(){
        return (
            <>
                <br/><b><span>Notes:</span></b><br/>
                <EditableText 
                    className={"notes-text"}
                    multiline={true}
                    minLines={25}
                    maxLines={25}
                    alwaysRenderInput={true}
                    isEditing={false}
                    value={this.props.notes_content}
                    onChange={this.props.onChangeNotes}
                    selectAllOnFocus={false}
                />
            </>
        );
    }
}


class ElementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
        }
        this.generateTree=this.generateTree.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
        this.handleNodeExpand = this.handleNodeExpand.bind(this);
        this.forEachNode = this.forEachNode.bind(this);
    }


    generateTree(){

        let node_map = this.props.getNodeMap();

        function renderITreeNode(node_id) {

            let node=node_map.get(node_id);

            let ITreeNode={
                id: node.node_id,
                hasCaret: (!node.child_ids || node.child_ids.length === 0) ?false : true,
                icon: <NodeIconFromType node_type={node.node_type}/>,
                label: node.text?node.text:"["+NodeAltTextFromType(node.node_type)+"]",
                childNodes:[],
            };

            for(let child_node_id of node.child_ids)
                ITreeNode.childNodes.push(renderITreeNode(child_node_id));

            return ITreeNode;
        }


        let ITreeNodes = [];

        for(let node of node_map.values())
            if(!node.parent_id)
                ITreeNodes.push(renderITreeNode(node.node_id));
        
        this.setState({nodes:ITreeNodes});
    }

    handleNodeClick (nodeData, _nodePath, e) {
        
        let node_map = this.props.getNodeMap();

        const originallySelected = nodeData.isSelected;
        if (!e.shiftKey) {
            this.forEachNode(this.state.nodes, n => {n.isSelected = false; node_map.get(n.id).highlight=false;this.props.updateNode(n.id);});
        }
        node_map.get(nodeData.id).highlight=nodeData.isSelected = originallySelected == null ? true : !originallySelected;
        
        //true;
        this.props.updateNode(nodeData.id);
        this.setState(this.state);
    };

    handleNodeCollapse (nodeData){
        nodeData.isExpanded = false;
        this.setState(this.state);
    };

    handleNodeExpand (nodeData) {
        nodeData.isExpanded = true;
        this.setState(this.state);
    };

    forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            this.forEachNode(node.childNodes, callback);
        }
    }
    componentDidMount(){
        this.generateTree();
    }
    componentWillUnmount(){ //un-highlight all before closing tab
        let node_map = this.props.getNodeMap();
        this.forEachNode(this.state.nodes, n => {n.isSelected = false; node_map.get(n.id).highlight=false;this.props.updateNode(n.id);});
    }
    render(){
        return (
            <>
                <Button onClick={this.generateTree}>
                    Refresh
                </Button>
                <Tree 
                    contents={this.state.nodes}
                    onNodeClick={this.handleNodeClick}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                />
            </>
        );
    }
}

class LawInput extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            selected_text:"",
            number_in_text:0,
            delete_selection:true,
            delete_all:false,
            select_target_selected:false,
        }
        this.onChangeCheckbox=this.onChangeCheckbox.bind(this);
        this.onChangeCheckboxWorkspace=this.onChangeCheckboxWorkspace.bind(this);
        this.ripText=this.ripText.bind(this);
        this.setSelectTargetButtonStatus=this.setSelectTargetButtonStatus.bind(this);
    }

    onChangeCheckbox(element){
        this.setState({ delete_selection:element.target.checked });
    }
    onChangeCheckboxWorkspace(element){
        this.setState({ delete_all:element.target.checked });

    }

    ripText(NodeType){
        //check if highlighted text
        //cut out highlighted text (identify the highlighted text and remove it)
        //add new node of the proper type with that highlighted text
        //let selected_text = ;

        let spawn_text = this.state.selected_text;

        if(!spawn_text){
            if(this.props.text_content && this.state.delete_all)
                {
                    spawn_text = this.props.text_content;
                    this.props.onChange("");
                }
            /*else
                return;*/;
            }
        else
            {
                this.setState({selected_text:""})
                this.props.onChange(this.state.text_with_selection_deleted);
            }

        let node_id = 0;
        let node_map = this.props.getNodeMap();

        if(this.props.target_id && node_map.get(this.props.target_id)){
            let parent_target_node = getUltimateParentofNodeID(this.props.target_id, node_map);

            if(!NodeNestableFromTopbar(NodeType) || !NodeNestIntoable(parent_target_node.node_type)){ //If it's a container, spawn it below the target
                node_id = this.props.addNode(undefined, NodeType, this.props.target_id, 0, 0, spawn_text, this.props.citation_content, !this.props.citation_content);
                setTimeout(() => {this.props.targetID(node_id);}, 100);
            }
            else { //otherwise, spawn it as a child of the target -- and if it's a Header, make it the new target
                node_id = this.props.addNode(this.props.target_id, NodeType, undefined, 0, 0, spawn_text, this.props.citation_content, !this.props.citation_content);
                if([HeaderNode].includes(NodeType))
                    setTimeout(() => {this.props.targetID(node_id);}, 100);
            }
        }
        else{
            node_id = this.props.addNode(undefined, NodeType, undefined, 200, 200, spawn_text, this.props.citation_content, !this.props.citation_content);
            if(NodeNestIntoable(NodeType))
                setTimeout(() => {this.props.targetID(node_id);}, 100);
        }
       
    }

    setSelectTargetButtonStatus(flag){
        this.setState({select_target_selected:flag});
    }

    render(){

                return (
            <>
                <b><span>&nbsp;Citation:</span></b><br/>
                <EditableText
                    multiline={true}
                    minLines={2}
                    maxLines={2}
                    alwaysRenderInput={true}
                    isEditing={false}
                    value={this.props.citation_content}
                    onChange={this.props.onChangeCitation}
                    placeholder={"Click to edit Citation"}
                    selectAllOnFocus={false}
                />
                <br/>
                <b>
                    <Tooltip
                        className={Classes.TOOLTIP_INDICATOR} 
                        content="Highlight Workspace text and click an icon below to send to plat">
                        <span>&nbsp;Workspace:</span>
                    </Tooltip>
                </b>
                <EditableText 
                    className={"workspace-text"}
                    multiline={true}
                    minLines={12}
                    maxLines={12}
                    alwaysRenderInput={true}
                    isEditing={true}
                    value={this.props.text_content}
                    onChange={this.props.onChange}
                    placeholder={"Click to edit Workspace text"}
                    onConfirm={(e)=>{
                            this.setState({selected_text:window.getSelection().toString()});

                            let ele = document.getElementsByClassName("workspace-text")[0].getElementsByTagName("textarea")[0];
                            let text_with_selection_deleted = ele.value;

                            console.log(this.state.delete_selection);
                            if(this.state.delete_selection){
                                text_with_selection_deleted = text_with_selection_deleted.slice(0, ele.selectionStart) + text_with_selection_deleted.slice(ele.selectionEnd);
                            }
                            this.setState({text_with_selection_deleted});
                        }
                            
                    }
                    selectAllOnFocus={false}
                />
                <br/>
                <b><span>Send to plat as:</span></b><ButtonGroup minimal={true}>
                    
                    {
                        NodeContainerAppearsInTopbar().map(
                            (node_type) => (
                                <Button
                                    key={"spawn"+NodeAltTextFromType(node_type)}
                                    onClick={()=>this.ripText(node_type)}
                                ><NodeIconFromType node_type={node_type}/></Button>
                            )
                        )
                    }
                <Divider/>
                
                    
                    {
                        NodeElementAppearsInTopbar().map(
                            (node_type) => (
                                <AnchorButton
                                    key={"spawn"+NodeAltTextFromType(node_type)}
                                    icon={<NodeIconFromType node_type={node_type}/>}
                                    onClick={()=>this.ripText(node_type)}
                                />
                            )
                        )
                    }
                </ButtonGroup>
                <br/><br/>
                <Switch
                    checked={this.state.delete_selection}
                    onChange={this.onChangeCheckbox}
                    label="Delete selected text once sent to plat" 
                />
                <Switch
                    checked={this.state.delete_all}
                    onChange={this.onChangeCheckboxWorkspace}
                    label="If no selected text, send entire Workspace to plat" 
                />
                <Button 
                    icon="send-to-graph" 
                    onClick={
                        (e)=>{
                            console.log("handleSideMenuDrag");
                            this.setSelectTargetButtonStatus(true);
                            document.body.style.cursor = "crosshair";
                            document.addEventListener("click", (e)=>{this.setSelectTargetButtonStatus(false); this.props.handleSideMenuDrag(e);}, {once:true});
                        }
                    }
                    active={this.state.select_target_selected}
                    minimal={true}
                >Select Target</Button>
                <Button 
                    icon="graph-remove"
                    onClick={
                        (e)=>{
                            this.props.targetID(null);
                        }
                    }
                    minimal={true}
                >Clear Target</Button>
            </>
        );
    }
}


class SideBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navbarTabId: "input",
            isOpen: true,
            target_id: 0,
            target_save_style: "",
        }
        this.handleSideMenuDrag=this.handleSideMenuDrag.bind(this);
        this.handleNavbarTabChange=this.handleNavbarTabChange.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.targetID=this.targetID.bind(this);
    }

    handleSideMenuDrag(e){
        let node_map = this.props.getNodeMap();
        let targeted_node_id = isTargetingNode(e.pageX, e.pageY, node_map, undefined, false, true);
        document.body.style.cursor = "auto";
        this.targetID(targeted_node_id);
        console.log("handleSideMenuDrag "+targeted_node_id);
      }

    handleNavbarTabChange(navbarTabId) {
        this.targetID(null);
        this.setState({ navbarTabId });
    }

    handleClose(){
        this.targetID(null);
        this.props.toggleDrawer();
    }

    targetID(node_id){
        let restoreID = () => {
            let node_map = this.props.getNodeMap();
            if(!this.state.target_id)
                return;
            let node = node_map.get(this.state.target_id);
            if(!node)
                return;
            let dom_node= node.myRef.current;
            if(!dom_node)
                return;
            dom_node.style.boxShadow = this.state.target_save_style;
        }
        let highlightID = (node_id) => {
            let node_map = this.props.getNodeMap();
            if(node_id){
                let node = node_map.get(node_id);
                if(!node)
                    return;
                let dom_node = node.myRef.current;
                if(!dom_node)
                    return;

                this.setState({target_save_style: dom_node.style.boxShadow});
                dom_node.style.boxShadow = "0px 0px 5px 5px "+getColorFromType(node.node_type);
                
            }
        }
        if(node_id === this.state.target_id)
            return;
        restoreID();
        highlightID(node_id);
        this.setState({target_id:node_id});
    }

    render() {

        return (
            <Drawer
                canEscapeKeyClose={true}
                canOutsideClickClose={false}
                hasBackdrop={false}
                isCloseButtonShown={true}
                isOpen={this.props.drawer_open}
                onClose={this.handleClose}
                enforceFocus={false}
                usePortal={true}
                portalClassName={'pass-through-portal'}
                className={'footer-editor-drawer'}
                size={Drawer.SIZE_SMALL}
                title={"Sidebar"}
            >
                <div className={'side-bar-contents'}>
                    <Tabs
                        animate={this.state.animate}
                        id="navbar"
                        large={true}
                        onChange={this.handleNavbarTabChange}
                        selectedTabId={this.state.navbarTabId}
                    >
                        <Tab id="input" title="Law Input" />
                        {/*<Tab id="notes" title="Notes" />*/}
                        {/*<Tab id="list" title="Elements List" />*/}
                        <Tab id="help" title="Help" />
                    </Tabs>
                
                    {this.state.navbarTabId === "input" &&
                        <LawInput
                            text_content={this.props.text_content}
                            onChange={this.props.onChangeLaw}
                            onChangeCitation={this.props.onChangeCitation}
                            citation_content={this.props.citation_content}
                            addNode = {this.props.addNode}
                            target_id={this.state.target_id}
                            targetID={this.targetID}
                            getNodeMap={this.props.getNodeMap}
                            handleSideMenuDrag={this.handleSideMenuDrag}
                        />
                    }
                    {this.state.navbarTabId === "notes" &&
                        <NotesTab
                            onChangeNotes={this.props.onChangeNotes}
                            notes_content = {this.props.notes_content}
                        />
                    }
                    {this.state.navbarTabId === "list" &&
                        <ElementList
                            getNodeMap={this.props.getNodeMap}
                            updateNode={this.props.updateNode}
                        />
                    }
                    {this.state.navbarTabId === "help" &&
                        <HelpTab
                        />
                    }
                </div>
            </Drawer>
        );
    }

}

export default SideBar;