import React from 'react';
import NestableNode from '../../shared/NestableNode';
import TextareaAutosize from 'react-textarea-autosize';
import {getUltimateParentofNodeID, adjustComponentLocation} from '../../../utils';
import { getColorFromType } from '../../shared/NodeConsts';


class HeaderNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false
        };
        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleTextHeightChange=this.handleTextHeightChange.bind(this);
        this.handleCitationChange=this.handleCitationChange.bind(this);
        this.handleCitationHeightChange=this.handleCitationHeightChange.bind(this);
        this.handleSubMenuDisable=this.handleSubMenuDisable.bind(this);
        this.handleSubMenuEnable=this.handleSubMenuEnable.bind(this);
        this.handleMenuDisable=this.handleMenuDisable.bind(this);
        this.handleMenuEnable=this.handleMenuEnable.bind(this);
    }


    handleSubMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=true;
    }

    handleSubMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=false;
    }
    
    handleMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = true;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = false;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleTextChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.text = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleTextHeightChange(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let parent_node = getUltimateParentofNodeID(node_id, node_map);
        let parent_node_id = parent_node.node_id;

        adjustComponentLocation(parent_node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    handleCitationChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.citation = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleCitationHeightChange(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let parent_node = getUltimateParentofNodeID(node_id, node_map);
        let parent_node_id = parent_node.node_id;

        adjustComponentLocation(parent_node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    
    render() { 
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        let parent_id = node.parent_id;
        let color = "#000000";

        if(parent_id)
            color = getColorFromType(getUltimateParentofNodeID(node_id, node_map).node_type);

        return (             
            <NestableNode
                {...this.props}
                enable_resizing={node.parent_id ? false : true}
                
            >
                <div 
                    className={"subtitle_node_frame"}  
                    style={{border:"1px solid "+color}}
                    onMouseOver={this.handleMenuEnable}
                    onMouseOut={this.handleMenuDisable}
                >
                     {/*<div className ={"subtitle_node_thick_line"} style={{borderTopColor:color}} />*/}
                    {(this.props.globalState().edit_mode ? true : node.text) &&
                        <div className="subtitle_node_text">
                            <TextareaAutosize 
                                id={this.props.node_id+"text"}
                                placeholder="[Subheading]"
                                onChange={this.handleTextChange}
                                onHeightChange={this.handleTextHeightChange}
                                value={node.text}
                                style={{overflow:"hidden"}}
                            />
                        </div>
                    }

                    {this.props.globalState().citations_mode && node.citation_enabled &&
                        (this.props.globalState().edit_mode || node.citation) &&
                        <div className="subtitle_node_citation">
                            <TextareaAutosize 
                                id={this.props.node_id+"citation"}
                                placeholder="[Citation]"
                                onChange={this.handleCitationChange}
                                onHeightChange={this.handleCitationHeightChange}
                                value={node.citation}
                                style={{overflow:"hidden"}}
                            />
                        </div>
                    }
                    <div className={"subtitle_node_nested"} ref={node.childrenRef} onMouseOver={this.handleSubMenuDisable} onMouseOut={this.handleSubMenuEnable}>
                            {this.props.children}
                    </div>
               
                </div>
            </NestableNode>
        );
    }
}
 
export default HeaderNode;