import React, {PureComponent} from 'react';
import NestableNode from '../../shared/NestableNode';
import TextAndCitationNode from '../text/TextAndCitationNode';
import TripleCheckbox from '../../shared/TripleCheckbox';
import {getUltimateParentofNodeID} from '../../../utils'

class AndNode extends PureComponent {
    render() {
        return (
            <AndOrNode {...this.props} andor="and" />
        );
    }
}

class OrNode extends PureComponent {
    render() {
        return (
            <AndOrNode {...this.props} andor="or" />
        );
    }
}

class AndOrNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            menu_visible: false,
            checkbox: false
         }

         this.handleSubMenuDisable=this.handleSubMenuDisable.bind(this);
         this.handleSubMenuEnable=this.handleSubMenuEnable.bind(this);
         this.handleMenuDisable=this.handleMenuDisable.bind(this);
         this.handleMenuEnable=this.handleMenuEnable.bind(this);

    }
    componentDidMount(){

        if(!this.props.children.length){
            let node_map = this.props.getNodeMap();
            let node_id = this.props.node_id;
            let node = node_map.get(node_id);
            this.props.addNode(this.props.node_id, TextAndCitationNode,undefined,0,0,node.text, node.citation);
            node.text="";
        }
    }

    handleSubMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=true;
    }

    handleSubMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=false;
    }

    handleMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = true;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = false;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }


    render() { 
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        let parent = getUltimateParentofNodeID(node_id, node_map);
        if(parent.collapsed)
            return (<></>);
         return ( 
            <NestableNode
                {...this.props}
                enable_resizing={node.parent_id ? false : true}
            >
                
                    <div 
                        className={this.props.andor+"_container_frame"+(node.highlight?" containerhighlight":"")}
                        onMouseOver={this.handleMenuEnable}
                        onMouseOut={this.handleMenuDisable}
                    >
                        <div className={this.props.andor+"_node"}>
                            {this.props.globalState().checkbox_mode &&
                                <div style={{display:"flex",alignItems:"center"}}>
                                    <TripleCheckbox
                                        node_map={node_map}
                                        node_id={node_id}
                                        updateNodeMap={this.props.updateNodeMap}
                                        updateNode={this.props.updateNode}
                                    />
                                </div>
                            }
                                <div className={this.props.andor+"_node_nested"} ref={node.childrenRef}  onMouseOver={this.handleSubMenuDisable} onMouseOut={this.handleSubMenuEnable}>
                                    {this.props.children}
                                </div>
                        </div>
                    </div>
                
            </NestableNode>
         );
         
    }
}
 
export {AndNode, OrNode};