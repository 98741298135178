import React from 'react';
import {
    Intent,
    Menu, 
    MenuItem, 
} from "@blueprintjs/core";

import { Auth, Storage } from 'aws-amplify';

class DeleteMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cloud_files:[],
            shared_cloud_files:[]
        }
        this.loadCloudPlat=this.loadCloudPlat.bind(this);
        this.listCloudPlats=this.listCloudPlats.bind(this);
        this.listSharedCloudPlats=this.listSharedCloudPlats.bind(this);
        this.listUserGroups=this.listUserGroups.bind(this);
    }
    

    async loadCloudPlat(name){
        await Storage.remove(name).then (result => this.props.toaster.show({
            message:"Successfully deleted file",
            icon: "upload",
            intent: Intent.SUCCESS,
            timeout: 2000,
        })).catch (err => this.props.toaster.show({message:"Error deleting",
        icon: "delete",
        intent: Intent.DANGER,
        timeout: 2000,}));
    }

    async listCloudPlats(){
        let info = await Auth.currentUserInfo();
        if(!info)
            return;
        if(!info.attributes)
            return;
        Storage.list(info.attributes.email+"/").then((data)=>
        this.setState({cloud_files:data.map((obj) => <MenuItem
                                            icon="code-block"
                                            text={obj.key}
                                            key={obj.key}
                                            onClick={()=>this.loadCloudPlat(obj.key)}
                                        />
        )})
        ).catch((data) => this.props.toaster.show({message:"Error accessing cloud",
        icon: "error",
        intent: Intent.DANGER,
        timeout: 2000,}));
    }

    async listSharedCloudPlats(){
        Storage.list("shared/").then((data)=>
        this.setState({shared_cloud_files:data.map((obj) => <MenuItem
                                            icon="code-block"
                                            text={obj.key}
                                            key={obj.key}
                                            onClick={()=>this.loadCloudPlat(obj.key)}
                                        />
        )})).catch((data) => this.props.toaster.show({message:"Error accessing cloud",
        icon: "error",
        intent: Intent.DANGER,
        timeout: 2000,}));
    }

    getUser(){
        return Auth.currentAuthenticatedUser()
        .then((userData) => userData)
        .catch(() => console.log("Not signed in"));

    }

    listUserGroups(){
        this.getUser().then(
            (userData) => {
                if(userData){
                    let is_admin = userData.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin");
                    if(is_admin) this.listSharedCloudPlats();
                    this.setState({is_admin});
                }
            }
        )
    }

    componentDidMount(){
        this.listCloudPlats();
        this.listUserGroups();
    }

    render() { 
        return    (
            <Menu>
                <MenuItem
                    icon="cloud-download"
                    text="Delete from Private Cloud"
                >
                    {this.state.cloud_files}
                </MenuItem>
                {this.state.is_admin &&
                    <MenuItem
                        icon="cloud-download"
                        text="Delete from Shared Cloud"
                    >
                        {this.state.shared_cloud_files}
                    </MenuItem>
                }
            </Menu>
        );
    }
}
 
export default DeleteMenu;