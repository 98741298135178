import {getAbsoluteOffsetFromBody} from './targeting_utils';




function isNodeTopLevel(node) {
    if(!node.parent_id)
        return true;
    else
        return false;
}

function listChildrenofNodeID(parent_node_id, node_map) {
    let children_list=[];

    let recursive_child_lister = (node_id) => {
        let node = node_map.get(node_id);
        for(let child_node_id of node.child_ids){
            recursive_child_lister(child_node_id);
            children_list.push(child_node_id);}
    }
    recursive_child_lister(parent_node_id);
    return children_list;
}

function listChildrenofNodeIDbyLevel(parent_node_id, node_map) {
    let children_list=[];

    let recursive_child_lister = (node_id, level) => {
        let node = node_map.get(node_id);
        for(let child_node_id of node.child_ids){
            recursive_child_lister(child_node_id, level+1);
            children_list.push({level, node_id:child_node_id});
        }
    }
    recursive_child_lister(parent_node_id, 1);
    return children_list;
}

function getUltimateParentofNodeID(node_id, node_map){
    let node=node_map.get(node_id);
    while(node.parent_id)
        node=node_map.get(node.parent_id);
    return node;
}

function updateNodeXY(node_id, node_map, dragging_x=0, dragging_y=0, dragging_node_id=undefined, not_dragging=false){
    //scenario #1: it's a top-level node: just use data.x and data.y
    //scenario #2: it's a nested node & is being dragged: use data.x and data.y + (offset + ultimate parent node xy)
    //scenario #3: it's a nested node & is not being dragged: 
    let node = node_map.get(node_id);
    let ultimate_parent_node=getUltimateParentofNodeID(node_id, node_map);
    let old_x = node.x;
    let old_y = node.y;

    if(!node.parent_id && !not_dragging){//top-level node being dragged
    node.x = dragging_x;
    node.y = dragging_y;
    }

    if(node.parent_id){    //nested node
        node.x = dragging_x;
        node.y = dragging_y;        
        //account for parent's offset
        let [x_offset_from_parent, y_offset_from_parent] = getAbsoluteOffsetFromBody(node_id, node_map);
        node.x+=x_offset_from_parent;
        node.y+=y_offset_from_parent;

        if(dragging_node_id === node_id || dragging_node_id !== ultimate_parent_node.node_id || not_dragging){         //if this node is being dragged   
            node.x+=ultimate_parent_node.x;
            node.y+=ultimate_parent_node.y;
        }
    }
    
    if(old_x === node.x && old_y === node.y)
        return false;
    else
        return true;
}

function updateNodeWidthHeight(node_id, node_map){
    let node = node_map.get(node_id);
    let old_width = node.width;
    let old_height = node.height;

    let node_DOM=node.myRef;
    let element=node_DOM.current;
    if(!element) return;

    node.height=element.offsetHeight;
    node.width=element.offsetWidth;

    if(old_width === node.width && old_height === node.height)
        return false;
    else
        return true;
}


function adjustComponentLocation(node_id, getNodeMap, updateNodeMap, updateEdge) {

        let node_map = getNodeMap();
        let node = node_map.get(node_id);

        let update_flag = false;

        if(!node.collapsed){ //updating children
            let node_children=listChildrenofNodeID(node_id, node_map);

            for(let child_node_id of node_children){
                if(updateNodeXY(child_node_id, node_map, 0, 0, undefined, true))
                    update_flag = true;
                if(updateNodeWidthHeight(child_node_id, node_map))
                    update_flag = true;
            }
        }

        if(updateNodeXY(node_id, node_map, 0, 0, undefined, true)) //updating top-level
            update_flag = true;
        if(updateNodeWidthHeight(node_id, node_map))
            update_flag = true;
            
        if(update_flag)
        {
            updateNodeMap(node_map, () => {updateEdge(node_id);});
        }
}

export {isNodeTopLevel, listChildrenofNodeID, updateNodeXY, updateNodeWidthHeight, getUltimateParentofNodeID, listChildrenofNodeIDbyLevel, adjustComponentLocation};