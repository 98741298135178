import React, { Component, PureComponent } from 'react';
import ContainerTitle from './ContainerTitle'
import NestableNode from '../shared/NestableNode';
import { classToStringNodeType } from '../shared/NodeConsts';

class ConsequenceNode extends PureComponent {
    render() {
        return (
            <Container {...this.props}/>
        );
    }
}

class EnumerationNode extends PureComponent {
    render() {
        return (
            <Container {...this.props}/>
        );
    }
}

class ExceptionNode extends PureComponent {
    render() {
        return (
            <Container {...this.props}/>
        );
    }
}

class RuleNode extends PureComponent {
    render() {
        return (
            <Container {...this.props}/>
        );
    }
}

class CaseNode extends PureComponent {
    render() {
        return (
            <Container {...this.props}/>
        );
    }
}

class Container extends Component {

    constructor(props) {
        super(props);
        this.handleSubMenuDisable=this.handleSubMenuDisable.bind(this);
        this.handleSubMenuEnable=this.handleSubMenuEnable.bind(this);
        this.handleMenuDisable=this.handleMenuDisable.bind(this);
        this.handleMenuEnable=this.handleMenuEnable.bind(this);
    }

    handleSubMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=true;
    }

    handleSubMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.over_sub=false;
    }

    handleMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = true;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.menu_disabled = false;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    render() {
        
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);

        return(
            <NestableNode
                {...this.props}
                handleContainerCollapse={this.handleContainerCollapse}
            >
                <div 
                    className={"container_box " + classToStringNodeType(node.node_type) + "_frame"+(node.highlight?" containerhighlight":"")}
                    onMouseOver={this.handleMenuEnable}
                    onMouseOut={this.handleMenuDisable}
                >
                    <ContainerTitle
                        node_id={node_id}
                        globalState={this.props.globalState}
                        handleEdgeDrag={this.props.handleEdgeDrag}
                        addNode={this.props.addNode}
                        removeNode={this.props.removeNode}
                        getNodeMap={this.props.getNodeMap}
                        updateNodeMap={this.props.updateNodeMap}
                        updateNode={this.props.updateNode}
                        updateEdge={this.props.updateEdge}
                        handleContainerCollapse={this.handleContainerCollapse}
                    >
                        <div className="node_body" ref={node.childrenRef} onMouseOver={this.handleSubMenuDisable} onMouseOut={this.handleSubMenuEnable}>
                            {
                                this.props.children
                            }
                    
                        </div>
                    </ContainerTitle>
                </div>
            </NestableNode>
        )
    }
}

export default Container;
export {RuleNode, ExceptionNode, EnumerationNode, ConsequenceNode, CaseNode};