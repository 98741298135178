import React, {Component} from 'react';

import NodeMapHolder from './components/NodeMapHolder'
import 'react-dropdown/style.css';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';


class App extends Component {

  render() {
    return (
          <div style={{"WebkitUserSelect": "none", "MozUserSelect": "none"}}>
            <NodeMapHolder />
          </div>  
    );
  }
}

export default withAuthenticator(App);