import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import NestableNode from '../../shared/NestableNode';
import {getUltimateParentofNodeID, adjustComponentLocation} from '../../../utils';
import { NodeAltTextFromType } from '../../shared/NodeConsts';

class TextAndCitationNode extends React.Component {
    constructor(props) {
        super(props);
        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleCitationChange=this.handleCitationChange.bind(this);
        this.handleTextHeightChange=this.handleTextHeightChange.bind(this);
        this.handleSubMenuDisable=this.handleSubMenuDisable.bind(this);
        this.handleSubMenuEnable=this.handleSubMenuEnable.bind(this);
    }

    handleTextHeightChange(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let parent_node = getUltimateParentofNodeID(node_id, node_map);
        let parent_node_id = parent_node.node_id;

        adjustComponentLocation(parent_node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    handleTextChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.text = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleCitationChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.citation = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleSubMenuDisable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        let parent_id = node.parent_id;
        let parent_node = node_map.get(parent_id);
        parent_node.over_sub_override=true;
    }

    handleSubMenuEnable() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        let parent_id = node.parent_id;
        let parent_node = node_map.get(parent_id);
        parent_node.over_sub_override=false;
    }

    render() {
        let style = {};

        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        let parent_node = node_map.get(node.parent_id);
        let parent_type = "Text";
        if(parent_node)
            parent_type=NodeAltTextFromType(parent_node.node_type);
        if(!node.parent_id){
            style = {
                width: "300px"
            }
        }
        return (
            <NestableNode
                {...this.props}
                enable_dragging={false}
                enable_resizing={false}
                disable_menu={true}
            >
                <div 
                    className={"embedded_text_node_textarea"+(node.highlight?" containerhighlight":"")} 
                    style={style}
                    onMouseOver={this.handleSubMenuDisable} onMouseOut={this.handleSubMenuEnable}
                >
                    <div className="embedded_text_box_node">
                        {(this.props.globalState().edit_mode ? true : node.text) &&
                            <div className="embedded_text_box_node_text">
                                <TextareaAutosize 
                                    id={this.props.node_id+"text"}
                                    placeholder={"["+parent_type+"]"}
                                    onChange={this.handleTextChange}
                                    onHeightChange={this.handleTextHeightChange}
                                    value={node.text}
                                    style={{overflow:"hidden"}}
                                />
                            </div>
                        }
                        
                        {this.props.globalState().citations_mode && parent_node.citation_enabled &&

                            (this.props.globalState().edit_mode ? true : node.citation) &&

                            <div className="embedded_text_box_node_citation">
                                <TextareaAutosize
                                    id={this.props.node_id+"citation"}
                                    placeholder="[Citation]"
                                    onChange={this.handleCitationChange}
                                    onHeightChange={this.handleTextHeightChange}
                                    value={node.citation}
                                />
                            </div>
                        }
                    </div>
                </div>
            </NestableNode>
        );
    }
}

export default TextAndCitationNode;