import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {adjustComponentLocation} from '../../utils';
import { NodeAltTextFromType, NodeIconFromType } from '../shared/NodeConsts';

class ContainerTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false,
            mouse_x:0,
            mouse_y:0
        };
        this.handleCitationChange = this.handleCitationChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleMenuVisibility = this.handleMenuVisibility.bind(this);
        this.handleTextHeightChange=this.handleTextHeightChange.bind(this);
    }
    
    handleTextHeightChange(){
        let node_id = this.props.node_id;

        adjustComponentLocation(node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    handleTextChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.text = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleCitationChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.citation = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleMenuVisibility(e){
        this.setState({
            menu_visible : !this.state.menu_visible,
            mouse_x : e.pageX,
            mouse_y : e.pageY,
        });
    }

    render() {

        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);

        let slim_mode=false;

        if(!(this.props.globalState().edit_mode || node.text || node.citation))
            slim_mode=true;

        if(slim_mode)

            return(
                <div className="container_title">
                    <div className="node_icon_column">
                        <div className={"container_icon drag"+node.node_id}>
                            <NodeIconFromType node_type={node.node_type} />
                        </div>
                    </div>
                    <div className="node_children_column">
                        {this.props.children}
                    </div>
                </div>
            );

        else

            return(
                <>
                    <div 
                        className={"container_title"}
                    >                        
                        <div className={"container_icon drag"+node.node_id}>
                            {/*<img 
                                style={{
                                    pointerEvents: "none"
                                }}
                                draggable="false" 
                                alt="Icon" 
                                src={NodeIconFromType(node.node_type)} 
                            />*/}
                            {<NodeIconFromType node_type={node.node_type} />}
                        </div>
                    
                        <div className="container_title_and_citation">
                            {(this.props.globalState().edit_mode || node.text) &&

                                <div className="container_title_text">
                                    <TextareaAutosize
                                        id={node.node_id+"title"}
                                        placeholder={"["+NodeAltTextFromType(node.node_type)+"]"}
                                        value={node.text}
                                        onChange={this.handleTextChange}
                                        onHeightChange={this.handleTextHeightChange}
                                        style={{overflow:"hidden"}}
                                    />
                                </div>
                            }
                            {this.props.globalState().citations_mode && node.citation_enabled &&

                                (this.props.globalState().edit_mode || node.citation) &&
                            
                                <div className="container_citation_text">
                                    <TextareaAutosize
                                        id={node.node_id+"citation"}
                                        placeholder="[Citation]"
                                        value={node.citation}
                                        onChange={this.handleCitationChange}
                                        onHeightChange={this.handleTextHeightChange}
                                        style={{overflow:"hidden"}}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {this.props.children}
                </>
            );
    }
}

export default ContainerTitle;