import React from 'react';
import { Resizable } from "re-resizable";
import { updateNodeWidthHeight, listChildrenofNodeID, updateNodeXY} from '../../utils';

class ResizableWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.onResize=this.onResize.bind(this);
        this.onResizeStop=this.onResizeStop.bind(this);
        this.state = {delta_width:0};
    }

    onResize(node_id, refToElement, delta){
        if(delta.width === this.state.delta_width){
            console.log("skip resize");
            return;}
        this.setState({delta_width : delta.width});

        let dom_node = refToElement;
            if(!dom_node) return;
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node = node_map.get(node_id);

        window.dispatchEvent(new Event('resize')); //trigger TextareaAutosize

        node.width = dom_node.offsetWidth; //reset width and height of resized element
        node.height = dom_node.offsetHeight;

        if(!node.collapsed){
            let node_children=listChildrenofNodeID(node_id, node_map); //reset width and height, and XY coords, of children

            for(let child_node_id of node_children)
                {
                    updateNodeWidthHeight(child_node_id, node_map);
                    updateNodeXY(child_node_id, node_map, undefined, undefined, undefined, true);
                }
        }
        
        this.props.updateNodeMap(node_map, () => {this.props.updateEdge(node_id);});
    }

    onResizeStop(){
        this.setState({delta_width:0});
    }

    render() {
        return (
                <Resizable
                    enable={this.props.enable}
                    maxHeight={"auto"}
                    minWidth={"150"}
                    onResize={(event, direction, refToElement,delta)=>this.onResize(this.props.node_id, refToElement, delta)}
                    onResizeStop={(event, direction, refToElement,delta)=>this.onResizeStop()}
                    grid={[4,4]}
                >
                    {this.props.children}
                </Resizable>
        );

    }
}

export default ResizableWrapper;