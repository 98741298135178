import React from 'react';
import TextNode from '../elements/text/TextNode';
import HeaderNode from '../elements/header/HeaderNode';
import {AndNode, OrNode} from '../elements/andor/AndOrNode'
import {RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode} from '../containers/Container';
import NoteNode from '../containers/Note';
import TextAndCitationNode from '../elements/text/TextAndCitationNode';
import { getUltimateParentofNodeID } from '../../utils';

import ConsequenceIcon from '../icons/consequence.svg'
import ExceptionIcon from '../icons/old icons/exception.svg';
import EnumerationIcon from '../icons/old icons/enumeration.svg';
import RuleIcon from '../icons/rule.svg';
import CaseIcon from '../icons/case.svg';
import AndIcon from '../icons/and.svg';
import NoteIcon from '../icons/note.svg';
import OrIcon from '../icons/or.svg';
import TextIcon from '../icons/text.svg';

//import ConsequenceIcon from '../containers/consequence.svg';
//import ExceptionIcon from '../containers/exception.svg';
//import EnumerationIcon from '../containers/enumeration.svg';
//import RuleIcon from '../containers/rule.svg';
import { Icon } from '@blueprintjs/core';

function stringToClassNodeType(node_type){
    const NodeTypebyString = new Map([
        ["rule_node", RuleNode],
        ["consequence_node", ConsequenceNode],
        ["enumeration_node", EnumerationNode],
        ["exception_node", ExceptionNode],
        ["case_node", CaseNode],
        ["note_node", NoteNode],
        ["text_box_node", TextNode],
        ["subtitle_node", HeaderNode],
        ["text_citation_box_node", TextAndCitationNode],
        ["or_container", OrNode],
        ["and_container", AndNode],
    ]);
    return NodeTypebyString.get(node_type);
}

function classToStringNodeType(node_type_string){
    const NodeStringbyType = new Map([
        [RuleNode, "rule_node"],
        [ConsequenceNode, "consequence_node"],
        [EnumerationNode, "enumeration_node"],
        [ExceptionNode, "exception_node"],
        [CaseNode, "case_node"],
        [NoteNode, "note_node"],
        [TextNode, "text_box_node"],
        [HeaderNode, "subtitle_node"],
        [TextAndCitationNode, "text_citation_box_node"],
        [OrNode, "or_container"],
        [AndNode, "and_container"],

    ]);
    return NodeStringbyType.get(node_type_string);
}

function getColorFromType (node_type, node_id = null, node_map = null) {
    const NodeColorbyType = new Map([
        [RuleNode, "#000000"],
        [ConsequenceNode, "#15B371"],
        [EnumerationNode, "#2B95D6"],
        [ExceptionNode, "#DB3737"],
        [CaseNode, "#000000"],
        [NoteNode, "#62707F"],
        [TextNode, "#000000"],
        [HeaderNode, "#000000"], // and inherits ultimate parent type color if is nested
        [TextAndCitationNode, "#000000"],
        [OrNode, "#000000"],
        [AndNode, "#000000"],
    ]);

    let retrieve_node_type = node_type;
    if(node_type === HeaderNode)
        if(node_id && node_map.get(node_id).parent_id)
             retrieve_node_type = getUltimateParentofNodeID(node_id, node_map).node_type;
    return NodeColorbyType.get(retrieve_node_type);
}

/////////NESTING

function NodeNestable(node_type){
    const NestableNodes = [RuleNode, ConsequenceNode, EnumerationNode, ExceptionNode, TextNode, HeaderNode, TextAndCitationNode, OrNode, AndNode];
    if(NestableNodes.includes(node_type))
        return true;
    else
        return false;
}

function NodeNestIntoable(node_type){
    const NestIntoableNodes = [RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode, HeaderNode, AndNode, OrNode];
    if(NestIntoableNodes.includes(node_type))
        return true;
    else
        return false;
}

function NodeNestableFromTopbar(node_type){
    const NestableFromTopbar=[OrNode, AndNode,HeaderNode,TextNode];
    if(NestableFromTopbar.includes(node_type))
        return true;
    else
        return false;
}
function NodeOnlyNestsTopLevel(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, HeaderNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeContainerCanNestInto(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeOnlyNestsTopLevelAndConvertsToHeader(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode].includes(node_type)){
        return true;
    } 
    return false;
}

/////////DRAGGING

function NodeDraggable(node_type){
    const UndraggableNodes = [TextAndCitationNode];
    if(UndraggableNodes.includes(node_type))
        return false;
    else
        return true;
}

function NodeContainer(node_type) {
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode].includes(node_type)){
        return true;
    } 
    return false;
}
/////////CHANGING
function NodeCanChangeType(node_type) {
    const InterchangeableContainers = [RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode];
    const InterchangeableElements = [OrNode, AndNode];

    if (InterchangeableContainers.includes(node_type)){
        return InterchangeableContainers.filter((x)=>x!==node_type);
    }
    if(InterchangeableElements.includes(node_type))
    return InterchangeableElements.filter((x)=>x!==node_type);

    return false;
}


function NodeContainerorSubtitle(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode, HeaderNode].includes(node_type)){
        return true;
    } 
    return false;
}



function NodeCollapsible(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode, HeaderNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeHasCitation(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode, HeaderNode, AndNode, OrNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeCanHaveHeaders(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeIconFromType(props){
    let NodesWithIcons = new Map([
        [RuleNode, RuleIcon],
        [ExceptionNode, ExceptionIcon],
        [ConsequenceNode, ConsequenceIcon],
        [EnumerationNode, EnumerationIcon],
        [CaseNode, CaseIcon],
        [NoteNode, NoteIcon],
        [AndNode, AndIcon],
        [OrNode, OrIcon],
        [TextNode, TextIcon],
        
    ]);
    let NodesWithText = new Map([
        [HeaderNode, "header"],
    ])
    
    let height = props.height ? props.height : "20px";
    let width = props.width ? props.width : "20px";

    if(NodesWithIcons.get(props.node_type))
        return <img draggable={false} alt={NodeAltTextFromType(props.node_type)} title={NodeAltTextFromType(props.node_type)} src={NodesWithIcons.get(props.node_type)} height={height} width={width}/>;
    else
        return <Icon draggable={false} htmlTitle={NodeAltTextFromType(props.node_type)} icon={NodesWithText.get(props.node_type)} height={height} width={width}/>;

}

function NodeAltTextFromType(node_type){
    let NodeAltTextByType = new Map([
        [RuleNode, "If"],
        [ExceptionNode, "Exception"],
        [ConsequenceNode, "Then"],
        [EnumerationNode, "Description"],
        [CaseNode, "Case"],
        [NoteNode, "Note"],
        [AndNode, "And"],
        [OrNode, "Or"],
        [TextNode, "Text"],
        [TextAndCitationNode, "Text and Citation"],
        [HeaderNode, "Subheading"]
    ]);
    return NodeAltTextByType.get(node_type);
}

function NodeContainerAppearsInTopbar(){
    return [RuleNode, ConsequenceNode, ExceptionNode, EnumerationNode, CaseNode, NoteNode];
}

function NodeElementAppearsInTopbar(){
    return [HeaderNode, TextNode, AndNode, OrNode];
}
function NodeElementAppearsInTopbarMinusHeaders(){
    return NodeElementAppearsInTopbar().filter((i)=>i!==HeaderNode);
}

function NodeHotkeyTextByType(props){
    let NodeHotkeyTextsByType = new Map([
        [RuleNode, <><u>I</u>f</>],
        [ExceptionNode, <>Exce<u>p</u>tion</>],
        [ConsequenceNode, <><u>T</u>hen</>],
        [EnumerationNode, <><u>D</u>escription</>],
        [CaseNode, <><u>C</u>ase</>],
        [NoteNode, <><u>N</u>ote</>],
        [AndNode, <><u>A</u>nd</>],
        [OrNode, <><u>O</u>r</>],
        [TextNode, <>T<u>e</u>xt</>],
        [HeaderNode, <>Sub<u>h</u>eading</>]
    ]);
    return NodeHotkeyTextsByType.get(props.node_type);
}

function NodeHotkeyByType(node_type){
    let NodeHotkeysByType = new Map([
        [RuleNode, "i"],
        [ExceptionNode, "p"],
        [ConsequenceNode, "t"],
        [EnumerationNode, "d"],
        [CaseNode, "c"],
        [NoteNode, "n"],
        [AndNode, "a"],
        [OrNode, "o"],
        [TextNode, "e"],
        [TextAndCitationNode, ""],
        [HeaderNode, "h"]
    ]);
    return NodeHotkeysByType.get(node_type);
}

function NodeLineCanStart(node_type){
    if ([RuleNode, ExceptionNode, ConsequenceNode, EnumerationNode, CaseNode, NoteNode, HeaderNode].includes(node_type)){
        return true;
    } 
    return false;
}

function NodeLineCanEnd(node_type){
    if ([TextNode, TextAndCitationNode].includes(node_type)){
        return false;
    } 
    return true;
}

export {getColorFromType, stringToClassNodeType, classToStringNodeType, NodeNestIntoable,
    NodeDraggable, NodeNestableFromTopbar, NodeContainer, NodeCanChangeType, NodeContainerorSubtitle, NodeOnlyNestsTopLevelAndConvertsToHeader,
    NodeOnlyNestsTopLevel, NodeNestable, NodeContainerCanNestInto, NodeCollapsible,
    NodeHasCitation, NodeCanHaveHeaders, NodeIconFromType, NodeAltTextFromType,
    NodeContainerAppearsInTopbar, NodeElementAppearsInTopbar,
    NodeElementAppearsInTopbarMinusHeaders,
    NodeHotkeyTextByType, NodeHotkeyByType, NodeLineCanStart, NodeLineCanEnd};