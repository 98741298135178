import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import NestableNode from '../../shared/NestableNode';
import {getUltimateParentofNodeID, adjustComponentLocation} from '../../../utils';

/*import 'draft-js/dist/Draft.css';

import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';

import {Popover, PopoverInteractionKind} from '@blueprintjs/core'

const toolbarPlugin = createToolbarPlugin();
  const { Toolbar } = toolbarPlugin;*/



class TextNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false
        };

        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleTextHeightChange=this.handleTextHeightChange.bind(this);
        this.handleMenuVisibility = this.handleMenuVisibility.bind(this);
    }

    handleTextChange(e){
        let node_map = /*_.cloneDeep*/(this.props.getNodeMap());
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.text = e.target.value;//.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    handleTextHeightChange(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let parent_node = getUltimateParentofNodeID(node_id, node_map);
        let parent_node_id = parent_node.node_id;

        adjustComponentLocation(parent_node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    handleMenuVisibility(){
        this.setState({
            menu_visible : !this.state.menu_visible
        });
    }
    
    render() { 
        let style = {};

        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);

        if(!node.parent_id){
            style = {
                border: "1px solid #5C7080"
            }
        }     

        let parent = getUltimateParentofNodeID(node_id, node_map);
        if(parent.collapsed)
            return (<></>);

        
        return (             
            <NestableNode
                {...this.props}
                enable_resizing={node.parent_id ? false : true}
            >
                <div 
                    className={"text_box_node_frame"+(node.highlight?" containerhighlight":"")}  
                    style={style}
                >
                    {(this.props.globalState().edit_mode ? true : node.text) &&
                        <div className="text_box_node_text ">
                                <TextareaAutosize 
                                    id={this.props.node_id+"text"}
                                    placeholder="[Text]"
                                    onChange={this.handleTextChange}
                                    onHeightChange={this.handleTextHeightChange}
                                    value={node.text}
                                    style={{overflow:"hidden"}}
                                />
                            
                            {/*<Editor
                                editorState={node.richtext}
                                onChange={this.handleTextChange}
                                plugins={[toolbarPlugin]}
                            />
                            <Popover 
                            boundary={'window'} 
                            hoverCloseDelay={0} 
                            hoverOpenDelay={50} 
                            interactionKind={PopoverInteractionKind.CLICK} 
                            minimal={true} 
                            isOpen={true}
                            usePortal={true}
                            content={<Toolbar />}>
                                <div></div>
                            </Popover>*/}
                        </div>
                    }
                    {/*this.props.globalState().edit_mode && this.state.menu_visible &&
                        <ElementMenuContext 
                            {...this.props}
                            onlyRemove={true}
                            node_id={this.props.node_id}
                            onInteraction={this.handleMenuVisibility}
                            addNode={this.props.addNode}
                            removeNode={this.props.removeNode}
                            isOpen={this.state.menu_visible}
                        />
                    */}
                </div>
            </NestableNode> 
        );
    }
}
 
export default TextNode;