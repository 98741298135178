import React, { Component } from 'react';
import NestableNode from '../shared/NestableNode';
import {adjustComponentLocation} from '../../utils';
import { NodeIconFromType } from '../shared/NodeConsts';
import TextareaAutosize from 'react-textarea-autosize';

class NoteNode extends Component {

    constructor(props) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextHeightChange=this.handleTextHeightChange.bind(this);
    }

    handleTextHeightChange(){
        let node_id = this.props.node_id;

        adjustComponentLocation(node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
    }

    handleTextChange(e){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.text = e.target.value;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    render() {
        
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);

        return(
            <NestableNode
                {...this.props}
                handleContainerCollapse={this.handleContainerCollapse}
            >
                <div 
                    className={"note_box "+(node.highlight?" containerhighlight":"")}
                >
                    <>
                        <div 
                            className={"note_title"}
                        >                        
                            <div className={"note_icon drag"+node.node_id}>
                                {<NodeIconFromType node_type={node.node_type} />}
                            </div>
                        
                            <div className="note_title_text">
                                <TextareaAutosize
                                    id={node.node_id+"title"}
                                    placeholder="[Note]"
                                    value={node.text}
                                    onChange={this.handleTextChange}
                                    onHeightChange={this.handleTextHeightChange}
                                    style={{overflow:"hidden"}}
                                />
                            </div>
                        </div>
                        {this.props.children}
                    </>
                </div>
            </NestableNode>
        )
    }
}

export default NoteNode;