import React, { Component } from 'react';

import {
    Popover,
    ButtonGroup,
    AnchorButton,
    Position,
    PopoverInteractionKind,
    MenuItem,
    Intent,
    Menu,
    MenuDivider
} from "@blueprintjs/core"

import Hotkeys from 'react-hot-keys';
import { NodeCanHaveHeaders, NodeCollapsible, NodeCanChangeType, NodeHasCitation, NodeIconFromType, NodeNestIntoable, NodeElementAppearsInTopbar, NodeHotkeyByType, NodeHotkeyTextByType, NodeElementAppearsInTopbarMinusHeaders, NodeAltTextFromType, NodeLineCanStart, NodeContainerAppearsInTopbar } from './NodeConsts';

class SettingsMenu extends Component {
    constructor(props) {
        super(props);
        this.removeNode = this.removeNode.bind(this);
        this.collapseNode = this.collapseNode.bind(this);
        this.toggleCitation = this.toggleCitation.bind(this);
        this.highlightNode = this.highlightNode.bind(this);
    }

    removeNode(){
        this.props.savetoUndo("Delete");
        this.props.removeNode(this.props.node_id);
    }
    
    collapseNode(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.collapsed = !node.collapsed;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    toggleCitation(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.citation_enabled = !node.citation_enabled;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    highlightNode(){
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.highlight = !node.highlight;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }

    render() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
            if(!node) return <></>;

        return (
            <Popover
                boundary={'window'}
                hoverCloseDelay={0}
                hoverOpenDelay={50}
                interactionKind={PopoverInteractionKind.CLICK} 
                minimal={true}
                usePortal={true}
                content={
                <div> 
                    <Menu>
                        {NodeCollapsible(node.node_type) &&  
                            <Hotkeys
                                keyName="l"
                                onKeyDown={this.collapseNode}
                            >
                                <MenuItem icon="sort" onClick={this.collapseNode} text={<>Co<u>l</u>lapse</>} />
                            </Hotkeys>
                        }
                        {NodeHasCitation(node.node_type) &&
                            <Hotkeys
                                keyName="g"
                                onKeyDown={this.toggleCitation}
                            >
                                <MenuItem icon="sort" onClick={this.toggleCitation} text={<>To<u>g</u>gle citation</>} />
                            </Hotkeys>
                        }
                        <Hotkeys
                            keyName="h"
                            onKeyDown={this.highlightNode}
                        >
                            <MenuItem icon="sort" onClick={this.highlightNode} text={<><u>H</u>ighlight</>} />
                        </Hotkeys>
                        <Hotkeys
                            keyName="x"
                            onKeyDown={this.removeNode}
                        >
                                <MenuItem icon="cross" intent={Intent.WARNING} onClick={this.removeNode} text={<>Remove (<u>x</u>)</>} />
                        </Hotkeys>          
                    </Menu>
                </div>
            } position={Position.BOTTOM_LEFT}>
                {this.props.children}
            </Popover>
        );
    }
}

class ChangeMenu extends Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
    }

    change(node_type){
        this.props.savetoUndo("Change");
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
        node.node_type=node_type;
        this.props.updateNodeMap(node_map,()=>{this.props.updateNode(node_id);})
    }
    
    render() {
        let node_map = this.props.getNodeMap();
        let node_id = this.props.node_id;
        let node = node_map.get(node_id);
            if(!node) return <></>;

        return (
            <Popover
                boundary={'window'}
                hoverCloseDelay={0}
                hoverOpenDelay={50}
                interactionKind={PopoverInteractionKind.CLICK} 
                minimal={true}
                usePortal={true}
                content={
                <div> 
                    <Menu>        
                        {
                            NodeCanChangeType(node.node_type).map(
                                (node_type) =>
                                    <MenuItem key={"change"+NodeAltTextFromType(node_type)} icon={<NodeIconFromType node_type={node_type}/>} onClick={() => this.change(node_type)} text={<>Change to {NodeAltTextFromType(node_type)}</>}/>
                            )
                        }
                    </Menu>
                </div>
            } position={Position.BOTTOM_LEFT}>
                {this.props.children}
            </Popover>
        );
    }
}

class AddMenu extends Component {
    constructor(props) {
        super(props);
        this.addElement = this.addElement.bind(this);
        this.addContainer = this.addContainer.bind(this);
    }

    addElement(node_type){
        this.props.savetoUndo("Add "+NodeAltTextFromType(node_type));
        this.props.addNode(this.props.node_id, node_type);
    }

    addContainer(node_type){
        this.props.savetoUndo("Add "+NodeAltTextFromType(node_type));
        this.props.addNode(null, node_type, this.props.node_id);
    }
    
    render() {
        
        let node_map = this.props.getNodeMap();
        let node = node_map.get(this.props.node_id);


        if(!node)
            return <></>;

        let type_list = NodeElementAppearsInTopbar();
        if(!NodeCanHaveHeaders(node.node_type))
            type_list = NodeElementAppearsInTopbarMinusHeaders();


        let type_list2 = NodeContainerAppearsInTopbar();

        return (
            <Popover
                boundary={'window'}
                hoverCloseDelay={0}
                hoverOpenDelay={50}
                interactionKind={PopoverInteractionKind.CLICK} 
                minimal={true}
                usePortal={true}
                content={
                <div> 
                    <Menu>
                        {type_list.map(
                            (node_type) =>
                                <Hotkeys
                                    key={"hotkeychange"+NodeAltTextFromType(node_type)}
                                    keyName={NodeHotkeyByType(node_type)}
                                    onKeyDown={()=>this.addElement(node_type)}
                                >
                                    <MenuItem key={"add"+NodeAltTextFromType(node_type)} icon={<NodeIconFromType node_type={node_type}/>} onClick={() => this.addElement(node_type)} text={<NodeHotkeyTextByType node_type={node_type}/>} />
                                </Hotkeys>
                            )
                        }
                        <MenuDivider/>
                        {type_list2.map(
                            (node_type) =>
                                <Hotkeys
                                    key={"hotkeychange"+NodeAltTextFromType(node_type)}
                                    keyName={NodeHotkeyByType(node_type)}
                                    onKeyDown={()=>this.addContainer(node_type)}
                                >
                                    <MenuItem key={"add"+NodeAltTextFromType(node_type)} icon={<NodeIconFromType node_type={node_type}/>} onClick={() => this.addContainer(node_type)} text={<NodeHotkeyTextByType node_type={node_type}/>} />
                                </Hotkeys>
                            )
                        }
                    </Menu>
                </div>
            } position={Position.BOTTOM_LEFT}>
                {this.props.children}
            </Popover>
        );
    }
}

class HoverMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
    }
    render() {

        let node_map = this.props.getNodeMap();
        let node = node_map.get(this.props.node_id);
        if(!node)
            return <></>;

        return (
                <Popover 
                    disabled={this.props.globalState().is_dragging || this.props.disable_menu || !this.props.globalState().edit_mode}
                    boundary={'window'} 
                    autoFocus={false}
                    hoverCloseDelay={125}  
                    hoverOpenDelay={100} 
                    interactionKind={PopoverInteractionKind.HOVER} 
                    minimal={true}
                    usePortal={true}
                    enforceFocus={false}
                    content={ 
                    !(node.over_sub && !node.over_sub_override) && 
                    <div> 
                        <ButtonGroup minimal={true}>
                            <AnchorButton style={{cursor:"move"}} title="Click and drag" icon="move" className={"drag"+this.props.node_id}/>
                            <SettingsMenu
                                node_id={this.props.node_id}
                                updateNode={this.props.updateNode}
                                removeNode={this.props.removeNode}
                                getNodeMap={this.props.getNodeMap}
                                updateNodeMap={this.props.updateNodeMap}
                                savetoUndo={this.props.savetoUndo}
                            >
                                <AnchorButton title="Settings" icon="settings"/>
                            </SettingsMenu>

                            {NodeNestIntoable(node.node_type) &&
                                <AddMenu
                                    addNode={this.props.addNode}
                                    node_id={this.props.node_id}
                                    globalState={this.props.globalState}
                                    getNodeMap={this.props.getNodeMap}
                                    savetoUndo={this.props.savetoUndo}
                                >
                                    <AnchorButton title="Add object" icon="plus"/>
                                </AddMenu>
                            }
                            {NodeCanChangeType(node.node_type) &&  
                                <ChangeMenu
                                    node_id={this.props.node_id}
                                    updateNode={this.props.updateNode}
                                    getNodeMap={this.props.getNodeMap}
                                    updateNodeMap={this.props.updateNodeMap}
                                    savetoUndo={this.props.savetoUndo}
                                >
                                    <AnchorButton title="Change object type" icon="exchange"/>
                                </ChangeMenu>
                            }
                            {this.props.globalState().edit_mode && NodeLineCanStart(node.node_type) &&
                                <AnchorButton 
                                    icon="send-to-graph"
                                    title="Add a connection" 
                                    className="container_graph_button"
                                    onMouseDown={
                                        (e)=>{
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.props.handleEdgeDrag(e, this.props.node_id);
                                        }
                                    }
                                    minimal={true}
                                />
                            }

                        </ButtonGroup>
                    </div>
                } position={Position.TOP_LEFT}>
                    {this.props.children}
                </Popover>
        );
    }
}

export default HoverMenu;