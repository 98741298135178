import React from 'react';
import DraggableWrapper from './DraggableWrapper.js'
import {Portal} from '@blueprintjs/core'; 
import ResizableWrapper from './ResizableWrapper.js';
import {updateNodeXY, updateNodeWidthHeight, listChildrenofNodeID, adjustComponentLocation} from '../../utils';

import HoverMenu from './HoverMenu';
class NestableNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(){
        if(!this.props.getNodeMap().get(this.props.node_id).parent_id)
            window.requestAnimationFrame(() => {
                adjustComponentLocation(this.props.node_id, this.props.getNodeMap, this.props.updateNodeMap, this.props.updateEdge);
            });
    }

    componentDidMount(){
        if(!this.props.getNodeMap().get(this.props.node_id).parent_id)
            window.requestAnimationFrame(() => {
                let node_map = this.props.getNodeMap();
                updateNodeXY(this.props.node_id, node_map, 0, 0, undefined, true);
                updateNodeWidthHeight(this.props.node_id, node_map);
            });
    }

    render() {
        let node_map = this.props.getNodeMap();
        let node = node_map.get(this.props.node_id);

        let style = {width:"100%", zIndex: node.z_index};
   
        if(!node.parent_id){
            style = {
                position: "absolute",
                zIndex: node.z_index,
                width: node.width + "px"
            };
        }

        if(this.props.getDragging().is_dragging){ //slight z-index adjustment to get above later sibling nodes when dragging
            let nest_level = 0;
            let dragging_node_id = this.props.getDragging().dragging_node_id
            let children_node_ids = listChildrenofNodeID(node.node_id, node_map);
            children_node_ids.push(node.node_id);
            if(children_node_ids.find((e) => {return e ===dragging_node_id;})){
                nest_level = 1;
                let i_node = node_map.get(dragging_node_id);
                while(i_node.node_id !== node.node_id){
                    i_node=node_map.get(i_node.parent_id);
                    nest_level ++;
                }
            }
            style.zIndex += (nest_level);
        }

        let content = (
            <DraggableWrapper
                savetoUndo={this.props.savetoUndo}
                getNodeMap={this.props.getNodeMap}
                updateNodeMap={this.props.updateNodeMap}
                setDragging={this.props.setDragging}
                getDragging={this.props.getDragging}

                updateEdge={this.props.updateEdge}

                enable_dragging={this.props.enable_dragging}

                handle={".drag"+this.props.node_id}
                node_id={this.props.node_id}
                promoteNodeZIndex={this.props.promoteNodeZIndex}
                nestNode={this.props.nestNode}
                unnestNode={this.props.unnestNode}
                handleMousemove={this.props.handleMousemove}
                draggable_key={node.draggable_key}
                updateNode={this.props.updateNode}
            >    
                <div 
                    id={this.props.node_id} 
                    style={style}
                    ref={node.myRef}
                    key={node.node_id+"div"}
                >
                    <HoverMenu
                        savetoUndo={this.props.savetoUndo}
                        globalState={this.props.globalState}
                        node_id={this.props.node_id}
                        addNode={this.props.addNode}
                        removeNode={this.props.removeNode}
                        updateNode={this.props.updateNode}
                        getNodeMap={this.props.getNodeMap}
                        updateNodeMap={this.props.updateNodeMap}
                        disable_menu={this.props.disable_menu}
                        handleEdgeDrag={this.props.handleEdgeDrag}
                    >
                        <ResizableWrapper
                            setResizing={this.props.setResizing}
                            getNodeMap={this.props.getNodeMap}
                            updateNodeMap={this.props.updateNodeMap}
                            updateNode={this.props.updateNode}
                            updateEdge={this.props.updateEdge}
                            node_id={node.node_id}
                            enable={
                                this.props.globalState().edit_mode 
                                && this.props.enable_resizing 
                                && {
                                    top:false, 
                                    right:true, 
                                    bottom:false, 
                                    left:false, 
                                    topRight:false, 
                                    bottomRight:false, 
                                    bottomLeft:false, 
                                    topLeft:false
                                }
                            }
                        >
                        {this.props.children}
                        </ResizableWrapper>
                    </HoverMenu>
                </div>
            </DraggableWrapper>
        );
        
        let component = content;

        if(!node.parent_id){
            component = (
                <Portal 
                    container={document.getElementById('workspace')}
                >
                    {content}
                </Portal>
            )
        }
        return (
            component
        );
    }

}

NestableNode.defaultProps = {
    enable_dragging: true,
    enable_resizing: true
}

export default NestableNode;